import { SvgIcon } from "@mui/material";
import React from "react";

const IconPin = (props: { style: React.CSSProperties }) => {
  return (
    <SvgIcon style={props.style}>
      <svg
        viewBox="0 0 16 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.00175 9.86525C8.49925 9.86525 8.92458 9.68808 9.27775 9.33375C9.63108 8.97942 9.80775 8.5535 9.80775 8.056C9.80775 7.5585 9.63058 7.13308 9.27625 6.77975C8.92192 6.42658 8.49592 6.25 7.99825 6.25C7.50075 6.25 7.07542 6.42717 6.72225 6.7815C6.36892 7.13583 6.19225 7.56183 6.19225 8.0595C6.19225 8.557 6.36942 8.98233 6.72375 9.3355C7.07808 9.68867 7.50408 9.86525 8.00175 9.86525ZM8 17.5135C9.95633 15.7622 11.4534 14.0823 12.4913 12.474C13.5291 10.8657 14.048 9.457 14.048 8.248C14.048 6.425 13.4688 4.92633 12.3105 3.752C11.1522 2.57767 9.71533 1.9905 8 1.9905C6.28467 1.9905 4.84783 2.57767 3.6895 3.752C2.53117 4.92633 1.952 6.425 1.952 8.248C1.952 9.457 2.47092 10.8657 3.50875 12.474C4.54658 14.0823 6.04367 15.7622 8 17.5135ZM8 19.5095C5.48333 17.3288 3.59617 15.2994 2.3385 13.4212C1.08083 11.5429 0.452 9.8185 0.452 8.248C0.452 5.94033 1.19842 4.07208 2.69125 2.64325C4.18425 1.21442 5.95383 0.5 8 0.5C10.0462 0.5 11.8157 1.21442 13.3087 2.64325C14.8016 4.07208 15.548 5.94033 15.548 8.248C15.548 9.8185 14.9192 11.5429 13.6615 13.4212C12.4038 15.2994 10.5167 17.3288 8 19.5095Z"
          fill="#484C4E"
        />
      </svg>
    </SvgIcon>
  );
};

export default IconPin;
