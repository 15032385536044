import { SvgIcon } from "@mui/material";
import React from "react";

const IconLearnOutline = (props: {
  style: React.CSSProperties;
  className: string;
}) => {
  return (
    <SvgIcon
      style={props.style}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      className={props.className}
      x="0px"
      y="0px"
      fill="none"
    >
      <path
        fill="none"
        d="M9.79997 23.6902C9.79997 23.3523 9.6293 23.0372 9.34626 22.8526C8.03008 21.9942 6.9446 20.8225 6.18626 19.4415C5.42901 18.0626 5.02151 16.5174 5 14.9425C5.00381 9.50462 9.8002 5 16 5C22.2023 5 27 9.5082 27 14.949C27 20.3899 22.2022 24.8987 16 24.8987L15.9975 24.8987C15.2267 24.9006 14.4573 24.826 13.7006 24.6765C13.4838 24.6337 13.2589 24.6638 13.0611 24.7623L9.79997 26.3853V23.6902Z"
        stroke="#0C80A1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 14V19"
        stroke="#0C80A1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 9C15.8022 9 15.6089 9.0587 15.4445 9.16858C15.28 9.27846 15.1519 9.4347 15.0762 9.61742C15.0005 9.80015 14.9806 10.0012 15.0192 10.1952C15.0578 10.3892 15.153 10.5673 15.2929 10.7071C15.4327 10.847 15.611 10.9422 15.8049 10.9808C15.9989 11.0194 16.2 10.9996 16.3827 10.9239C16.5654 10.8482 16.7215 10.7201 16.8314 10.5556C16.9413 10.3912 17 10.1978 17 9.99999C17 9.73477 16.8947 9.4805 16.7072 9.29297C16.5196 9.10543 16.2652 9 16 9Z"
        fill="#0C80A1"
        stroke="#0C80A1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default IconLearnOutline;
