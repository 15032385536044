import moment from "moment";
import BaxiProgress, { BaxiProgressScale } from "./BaxiProgressComponent/BaxiProgressComponent";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { APP_ROUTES } from "../../constants/appRoutes";
import { calculateWearTimeRemaining } from "../utilityFunctions";

export interface DeviceWearTimeProps {
    recordingStartedAt: number,
    prescribedWearTimeDays: number,
    hideDescription?: boolean,
    size: BaxiProgressScale
}

/**
    Usage example:
        <DeviceWearTime
            size={BaxiProgressScale.LARGE}
            hideDescription
            prescribedWearTimeDays={10}
            recordingStartedAt={1729458281000}
        />
 */
const DeviceWearTime = ({recordingStartedAt = -1, prescribedWearTimeDays = -1, size,  hideDescription = false}: DeviceWearTimeProps) => {

    const remainingDays = useMemo(
        () =>
          calculateWearTimeRemaining(recordingStartedAt, prescribedWearTimeDays),
        [recordingStartedAt, prescribedWearTimeDays]
    );

    const [progressTitle, setProgressTitle] = useState('');
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const title = getWearTimeData()
        const percents = calculateWearPercentRemaining()

        setProgressTitle(title);
        setProgress(percents);
    }, [])

    return (
        <>
            <BaxiProgress
              scale={size}
              title={`${remainingDays}`}
              subtitle={progressTitle}
              value={progress}
            > 
                { hideDescription ? null : getWearTimeDescription() }
            </BaxiProgress>
        </>
    )

    function calculateWearPercentRemaining() {
        const percents = ((prescribedWearTimeDays - remainingDays) / prescribedWearTimeDays) * 100;
        return 100 - percents;
    }

    function getWearTimeData() {
        if (remainingDays > 0) {
           return remainingDays === 1
            ? `day left`
            : `days left`;
        } else return `days left`
    }

    function getWearTimeDescription() {
        if (remainingDays > 0) {
            const endDate = getWearEndDate()
            return (
                <Box sx={{marginTop: "5px", fontSize: "13px"}} display={"flex"} flexDirection={"column"}>
                    <span>prescription ends on </span>
                    <b>{endDate}</b>
                </Box>
            )
        } else return (
            <Box sx={{marginTop: "5px", fontSize: getWearTimeDescriptionFontSize()}}>
                To return the device 
                <br />follow 
                <Link style={{marginLeft: "3px"}} to={APP_ROUTES.HOW_TO_RETURN_DEVICE}>
                    <b>instruction</b>
                </Link>
            </Box>
        )
    }

    function getWearTimeDescriptionFontSize() {
        switch (size) {
            case BaxiProgressScale.LARGE:
                return "18px"
            case BaxiProgressScale.MEDIUM:
                return "13px"
        }
    }
 
    function getWearEndDate() {
        const endDateEpoch = recordingStartedAt + (prescribedWearTimeDays * 86400 * 1000);
        return moment(endDateEpoch).format("MMM Do, YYYY")
    }
}

export default DeviceWearTime;