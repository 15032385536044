import { SvgIcon } from "@mui/material";
import React from "react";

const IconHandset = (props: { style: React.CSSProperties }) => {
  return (
    <SvgIcon style={props.style}>
      <svg
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.4402 17.5C14.5557 17.5 12.6625 17.0618 10.7605 16.1855C8.85867 15.3092 7.11125 14.073 5.51825 12.477C3.92542 10.8808 2.69083 9.13333 1.8145 7.2345C0.938166 5.33583 0.5 3.44425 0.5 1.55975C0.5 1.25692 0.6 1.00458 0.8 0.80275C1 0.600916 1.25 0.5 1.55 0.5H4.8115C5.064 0.5 5.28675 0.582417 5.47975 0.74725C5.67275 0.911917 5.7955 1.11542 5.848 1.35775L6.42125 4.3C6.46092 4.573 6.45258 4.80758 6.39625 5.00375C6.33975 5.19992 6.23842 5.36467 6.09225 5.498L3.78275 7.74625C4.15442 8.42692 4.57908 9.07083 5.05675 9.678C5.53425 10.285 6.05125 10.8647 6.60775 11.4173C7.15642 11.9661 7.73975 12.4757 8.35775 12.9462C8.97575 13.4167 9.64308 13.8546 10.3598 14.2598L12.6038 11.9963C12.7603 11.8334 12.9498 11.7192 13.1723 11.6538C13.3946 11.5884 13.6257 11.5724 13.8655 11.6058L16.6422 12.1713C16.8948 12.2379 17.1008 12.3667 17.2605 12.5577C17.4202 12.7487 17.5 12.9654 17.5 13.2078V16.45C17.5 16.75 17.3991 17 17.1973 17.2C16.9954 17.4 16.7431 17.5 16.4402 17.5ZM3.073 6.327L4.85775 4.61925C4.88975 4.59358 4.91058 4.55833 4.92025 4.5135C4.92992 4.46867 4.92833 4.427 4.9155 4.3885L4.48075 2.15375C4.46792 2.10258 4.4455 2.06417 4.4135 2.0385C4.3815 2.01283 4.33983 2 4.2885 2H2.15C2.1115 2 2.07942 2.01283 2.05375 2.0385C2.02825 2.06417 2.0155 2.09625 2.0155 2.13475C2.06667 2.81808 2.1785 3.51225 2.351 4.21725C2.52333 4.92242 2.764 5.62567 3.073 6.327ZM11.773 14.9693C12.4358 15.2783 13.1272 15.5145 13.847 15.678C14.567 15.8413 15.2397 15.9384 15.8652 15.9693C15.9037 15.9693 15.9358 15.9564 15.9615 15.9307C15.9872 15.9051 16 15.873 16 15.8345V13.7308C16 13.6794 15.9872 13.6377 15.9615 13.6058C15.9358 13.5737 15.8974 13.5513 15.8462 13.5385L13.7462 13.1115C13.7077 13.0987 13.6741 13.0971 13.6453 13.1067C13.6164 13.1164 13.5859 13.1372 13.5538 13.1692L11.773 14.9693Z"
          fill="#484C4E"
        />
      </svg>
    </SvgIcon>
  );
};

export default IconHandset;
