import { CircularProgress } from "@mui/material";
import BIcon from "./Icons/IconComponent";
import IconWifiOff from "./Icons/SVGs/IconWifiOff";

const OfflineComponent = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div>
            <h3
              style={{
                textAlign: "center",
                textTransform: "uppercase",
                color: "#aeaeae",
              }}
            >
              No internet connection
            </h3>
            <BIcon
              icon={IconWifiOff}
              style={{
                height: "200px",
                minWidth: "100%",
                width: "100%",
                marginBottom: "-50px",
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress
                size={15}
                thickness={5}
                style={{ marginRight: "10px", color: "#aeaeae" }}
              />
              <h4
                style={{
                  textAlign: "center",
                  color: "#aeaeae",
                }}
              >
                Trying to reconnect...
              </h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OfflineComponent;
