import { SvgIcon } from "@mui/material";
import React from "react";

const IconBatteryCAMSuccess = (props: {
  style: React.CSSProperties;
  className: string;
}) => {
  return (
    <SvgIcon
      style={props.style}
      viewBox="0 0 130 130"
      className={props.className}
    >
      <path
        className="cls-4"
        d="M65,0a65,65,0,1,0,65,65A65,65,0,0,0,65,0Zm0,110a45,45,0,1,1,45-45A45,45,0,0,1,65,110Z"
      />
      <circle className="cls-5" cx="65" cy="65" r="55" />
      <polygon
        className="cls-6"
        points="66.84 45.21 47.88 70.34 65 70.34 63.17 87.05 82.38 60.63 65.68 60.63 66.84 45.21"
      />
    </SvgIcon>
  );
};

export default IconBatteryCAMSuccess;
