import { Box, CircularProgress } from "@mui/material"
import "./BaxiProgressStyle.css"

export interface BaxiProgressProps {
    value?: number,
    title?: string,
    subtitle?: string,
    children?: any,
    scale?: BaxiProgressScale
}

export const enum BaxiProgressScale {
    'SMALL' = 'sm',
    'MEDIUM' = 'md',
    'LARGE' = 'lg'
}

/**
    Usage example:

        <BaxiProgress
            scale={BaxiProgressScale.LARGE}
            title="22"
            subtitle="days left"
            description="prescription ends on 20 Aug 2024"
            value={45}
        />

 */
const BaxiProgress = ({value = 0, title = '', subtitle = '', children = null, scale = BaxiProgressScale.MEDIUM}: BaxiProgressProps) => {

    const flexRowCentered = {
        display: "flex",
        flexDirection: "row", 
        justifyContent: "center",
    }

    const flexColCentered = {
        display: "flex",
        flexDirection: "column", 
        justifyContent: "center",
    }

    const style = getStyle(scale);

    return (
        <Box sx={{
            ...flexRowCentered, 
            transform: "rotateY(180deg)",
            position: "relative"}}
        >
            <CircularProgress
                className="progress"
                size={style.progressCircleSize}
                variant="determinate" 
                value={value}
            />
            <Box 
                sx={{
                    ...flexColCentered,
                    height: style.progressCircleSize,
                    width: style.progressCircleSize,
                    position: "absolute",
                    textAlign: "center",
                    transform: "rotateY(180deg)",
                }}
            >
                <Box sx={{
                        fontFamily: "Geogrotesque",
                        fontWeight: style.titleStyle.bold ? "bold" : "normal",
                        fontSize: style.titleStyle.fontSize,
                        lineHeight: style.titleStyle.lineHeight
                    }}
                >
                    {title}
                </Box>
                { scale !== BaxiProgressScale.SMALL ? 
                    <>
                        {subtitle ?                         
                            <Box sx={{
                                fontWeight: style.subtitleStyle.bold ? "bold" : "normal",
                                fontSize: style.subtitleStyle.fontSize }}
                            >
                                {subtitle}
                            </Box> : null }
                        { children ? children : null }
                        
                    </> : null }

            </Box>
            <CircularProgress 
                    sx={{
                        position: "absolute",
                        textAlign: "center",
                        top: style.backgroundCircleMarginTop,
                        left: "auto",
                        color: "#DBE3EF",
                        zIndex: -1
                    }}
                    size={style.backgroundCircleSize}
                    variant="determinate"
                    thickness={2}
                    value={100}
            />
        </Box>
    )
    
    function getStyle(size: BaxiProgressScale): BaxiProgressStyle {
        switch(size) {
            case BaxiProgressScale.SMALL:
                return  {
                    progressCircleSize: '76px',
                    backgroundCircleSize: '73px', 
                    backgroundCircleMarginTop: '2px',
                    titleStyle: {
                        bold: true,
                        fontSize: '32px',
                    }
                }
            case BaxiProgressScale.MEDIUM: 
                return  {
                    progressCircleSize: '160px',
                    backgroundCircleSize: '153px', 
                    backgroundCircleMarginTop: '4px',
                    titleStyle: {
                        bold: true,
                        fontSize: '32px',
                        lineHeight: '24px'
                    },
                    subtitleStyle: {
                        fontSize: '18px',
                    },
                    descriptionStyle: {
                        fontSize: '12px',
                        paddingLR: '20px'
                    }
                }
            case BaxiProgressScale.LARGE: 
                return  {
                    progressCircleSize: '240px',
                    backgroundCircleSize: '232px', 
                    backgroundCircleMarginTop: '5px',
                    titleStyle: {
                        bold: true,
                        fontSize: '64px',
                        lineHeight: '50px'
                    },
                    subtitleStyle: {
                        fontSize: '32px',
                    },
                    descriptionStyle: {
                        fontSize: '18px',
                        paddingLR: '38px'
                    }
                }
        }
    }

    interface BaxiProgressStyle {
        backgroundCircleSize: string,
        backgroundCircleMarginTop: string,
        progressCircleSize: string,
        titleStyle?: BaxiProgressTitleStyle,
        subtitleStyle?: BaxiProgressTitleStyle,
        descriptionStyle?: BaxiProgressTitleStyle,
    
    }
    
    interface BaxiProgressTitleStyle {
        fontSize: string,
        bold?: boolean,
        paddingLR?: string,
        lineHeight?: string
    }
}

export default BaxiProgress;