import React from "react";

export interface IPatientDetails {
  deviceId: string;
  mappedToPatient: boolean;
  firstName: string;
  lastName: string;
  email: string;
}

const defaultDevice: IPatientDetails = {
  deviceId: "",
  mappedToPatient: false,
  firstName: "",
  lastName: "",
  email: ""
};

const PatientDetails = React.createContext<IPatientDetails>(defaultDevice);

export default PatientDetails;
