import { SvgIcon } from "@mui/material";
import React from "react";

const IconBattery = (props: { style: React.CSSProperties }) => {
  return (
    <SvgIcon
      style={props.style}
      viewBox="0 0 36 36"
      className={" circular-chart"}
    >
      <path
        className="circle"
        stroke="url(#paint0_linear_536_2218)"
        strokeDasharray="80, 100"
        d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <text x="50%" y="50%" textAnchor="middle" fontSize="5" color="#0C80A1">
        80%
      </text>
    </SvgIcon>
  );
};

export default IconBattery;
