import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";

export abstract class Client {
  private static instance: ApolloClient<NormalizedCacheObject>;

  public static getInstance(
    apiEndpointUrl?: string
  ): ApolloClient<NormalizedCacheObject> {
    const httpLink = new HttpLink({
      uri: apiEndpointUrl,
    });
    if (apiEndpointUrl != undefined && !Client.instance) {
      Client.instance = new ApolloClient({
        link: httpLink,
        cache: new InMemoryCache({
          addTypename: false, // to avoid __typename being added to the queries
        }),
      });
    }
    return Client.instance;
  }
}
