import { SvgIcon } from "@mui/material";
import React from "react";

const IconCAMSuccess = (props: {
  style: React.CSSProperties;
  className: string;
}) => {
  return (
    <SvgIcon
      style={props.style}
      viewBox="0 0 130 130"
      fill="none"
      className={props.className}
    >
      <path
        className="camsuccess"
        d="M65,25.41c-3.02,0-5.47,2.45-5.47,5.47s2.45,5.47,5.47,5.47c3.02,0,5.47-2.45,5.47-5.47S68.02,25.41,65,25.41z
	 M65,33.37c-1.37,0-2.49-1.12-2.49-2.49c0-1.37,1.12-2.49,2.49-2.49c1.37,0,2.49,1.12,2.49,2.49C67.49,32.25,66.37,33.37,65,33.37z
	 M65.05,13.42c-2.05-0.03-7.99,0.1-10.44,2.45c-0.79,0.76-1.21,1.71-1.21,2.75c0,4.15,1.59,27.3,1.66,28.26
	c0,0.06,0.37,5.04,5.8,6.68v46.2c0,0.32-0.05,0.65-0.15,0.95l-2.31,6.93l-0.04,0.13c-0.04,0.16-0.91,4.04,1.19,6.68
	c1.2,1.51,3.03,2.28,5.46,2.28c2.42,0,4.26-0.77,5.46-2.28c2.1-2.64,1.23-6.52,1.19-6.68l-2.35-7.06c-0.1-0.31-0.15-0.63-0.15-0.95
	V53.56c5.42-1.64,5.79-6.62,5.8-6.66c0.07-0.98,1.66-24.13,1.66-28.28c0-1.04-0.42-2-1.22-2.76C72.94,13.51,67.05,13.39,65.05,13.42
	z M64.57,16.4l0,0.02c0.16-0.01,0.32-0.01,0.49-0.01c0.17,0,0.34,0.01,0.5,0.01l0-0.02c1.19,0,2.56,0.08,3.82,0.27l-0.26,2
	c-0.03,0.25-0.25,0.44-0.5,0.44H65h-3.62c-0.25,0-0.47-0.19-0.5-0.44l-0.26-1.99C61.92,16.49,63.34,16.4,64.57,16.4z M64.97,51.17
	c-0.85,0.02-1.58-0.11-2.23-0.22v-0.98c0-0.26,0.21-0.46,0.46-0.46H65h1.8c0.26,0,0.46,0.21,0.46,0.46v0.98
	c-0.65,0.11-1.39,0.24-2.23,0.22H64.97z M68.75,108.52c0.16,0.79,0.33,2.89-0.64,4.1c-0.61,0.76-1.65,1.14-3.11,1.14
	c-1.46,0-2.51-0.38-3.11-1.14c-0.97-1.22-0.8-3.32-0.64-4.1l2.34-7.03c0.17-0.5,0.25-1.02,0.25-1.54v-45.9
	c0.28,0.01,0.53,0.04,0.83,0.04c0.11,0,0.22,0,0.33,0c0.11,0,0.22,0,0.33,0c0.29,0,0.55-0.03,0.83-0.04v45.9
	c0,0.52,0.08,1.05,0.25,1.54L68.75,108.52z M71.96,46.7c-0.02,0.28-0.23,1.82-1.72,3v-0.56c0-1.45-1.18-2.62-2.62-2.62H65h-2.62
	c-1.45,0-2.62,1.18-2.62,2.62v0.55c-1.48-1.18-1.69-2.73-1.72-3c-0.02-0.24-1.65-24.03-1.65-28.07c0-0.16,0.04-0.35,0.29-0.59
	c0.25-0.24,0.6-0.44,1.03-0.62l0.22,1.66c0.23,1.73,1.72,3.03,3.46,3.03H65h3.62c1.74,0,3.23-1.3,3.46-3.03l0.22-1.67
	c0.43,0.18,0.78,0.38,1.02,0.62c0.26,0.25,0.3,0.44,0.3,0.61C73.62,22.66,71.98,46.45,71.96,46.7z M49.56,47.97
	c0.07,1.23,0.38,2.43,0.9,3.55l3.88,8.23c0.02,0.04,2.04,4.02,2.04,10.8v16.28c-0.05,0.44-0.49,3.98-3.09,10.21
	c-2.87,6.88-2.84,11.93-2.82,14.08l0,0.36c0,2.23,1.73,8.96,7.22,12.03c0.11,1.12,0.49,2.94,1.84,4.38c1.3,1.39,3.14,2.1,5.47,2.1
	c2.33,0,4.17-0.71,5.47-2.1c1.35-1.44,1.74-3.26,1.84-4.38c5.48-3.07,7.22-9.8,7.22-12.03l0-0.36c0.01-2.16,0.05-7.2-2.82-14.08
	c-2.61-6.25-3.05-9.79-3.09-10.21V70.55c0-6.78,2.02-10.76,2.07-10.86l3.86-8.18c0.52-1.11,0.83-2.32,0.9-3.55l1.74-30.29
	c0-5.55-7.28-8.05-10.32-8.86V6.3c0-1.79-0.57-3.26-1.7-4.37C68.22,0.03,65.42-0.01,65,0c-0.43-0.01-3.22,0.04-5.16,1.93
	c-1.13,1.11-1.7,2.58-1.7,4.37v2.52c-3.04,0.8-10.32,3.31-10.32,8.86L49.56,47.97z M65.03,2.98C65.1,3.02,66.99,3,68.07,4.06
	c0.54,0.53,0.81,1.26,0.81,2.24l0,4.92l1.19,0.24c2.37,0.49,9.13,2.67,9.13,6.22c0,4.72-1.34,24.69-1.72,30.24
	c-0.05,0.76-0.25,1.51-0.57,2.2l-3.89,8.25c-0.1,0.19-2.38,4.61-2.38,12.19v16.35l0,0.11c0.01,0.16,0.33,4.03,3.31,11.18
	c2.64,6.32,2.61,10.94,2.59,12.91l0,0.38c0,1.51-1.51,7.54-6.31,9.75l-0.92,0.43l0.05,1.01c0,0.02,0.09,1.95-1.09,3.19
	c-0.72,0.76-1.82,1.14-3.28,1.14c-1.46,0-2.56-0.38-3.28-1.14c-1.18-1.24-1.09-3.17-1.09-3.18l0.07-1.02l-0.93-0.43
	c-4.8-2.21-6.31-8.24-6.31-9.75l0-0.38c-0.01-1.97-0.04-6.6,2.59-12.91c2.99-7.16,3.3-11.02,3.32-11.18l0-16.46
	c0-7.57-2.28-12-2.35-12.13l-3.92-8.31c-0.33-0.69-0.52-1.43-0.57-2.2c-0.38-5.54-1.72-25.52-1.72-30.24c0-3.55,6.76-5.73,9.13-6.22
	l1.19-0.24V6.3c0-0.97,0.26-1.7,0.8-2.23c1.1-1.09,3.02-1.09,3.06-1.09L65.03,2.98z"
      />
    </SvgIcon>
  );
};

export default IconCAMSuccess;
