import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import BIcon from "./Icons/IconComponent";
import IconQuestionBubble from "./Icons/SVGs/IconQuestionBubble";
import IconReadingHead from "./Icons/SVGs/IconReadingHead";
import IconMovieClapperboard from "./Icons/SVGs/IconMovieClapperboard";
import IconLightBulbRound from "./Icons/SVGs/IconLightBulbRound";
import faqs from "../data/faqs.json"
import BaxiAccordion from "./shared/BaxiAccordion";
import { useNavigate } from "react-router";
import { APP_ROUTES } from "../constants/appRoutes";


const LearnComponent = () => {
  const navigate = useNavigate()

  const [currFaqIdx, setCurrFaqIdx] = useState(null)

  return (
    <Grid 
      container 
      direction="column"
    >

      <Grid item className="page-header sticky-header">
          Learn
      </Grid>

      <Grid container  padding="0 24px" rowSpacing={{mobile: 3}} columnSpacing={{laptop: 20}}>
        <Grid item mobile={12} laptop={6} display="flex" justifyContent="space-between"
              onClick={() => navigate(APP_ROUTES.TECH_SUPPORT)}
        >
          <Box display="flex" alignItems="center">
            <BIcon icon={IconQuestionBubble} style={{height: "32px", width: "32px"}} />
            <Box paddingLeft="16px">Tech support</Box>
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <ChevronRightIcon />
          </Box>
        </Grid>
        <Grid item mobile={12} laptop={6} display="flex" justifyContent="space-between"
              onClick={() => navigate(APP_ROUTES.QUICK_START)} 
        >
          <Box display="flex" alignItems="center">
            <BIcon icon={IconReadingHead} style={{height: "32px", width: "32px"}} />
            <Box paddingLeft="16px">User manual</Box>
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <ChevronRightIcon />
          </Box>
        </Grid>
        <Grid item mobile={12} laptop={6} display="flex" justifyContent="space-between"
              onClick={() => navigate(APP_ROUTES.VIDEO_INSTRUCTIONS)} 
        >
          <Box display="flex" alignItems="center">
            <BIcon icon={IconMovieClapperboard} style={{height: "28px", width: "28px", paddingTop: "3px"}} />
            <Box paddingLeft="16px">Video instructions</Box>
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <ChevronRightIcon />
          </Box>
        </Grid>
        <Grid item mobile={12} laptop={6} display="flex" justifyContent="space-between"
              onClick={() => navigate(APP_ROUTES.IMPORTANT_TIPS)} 
        >
          <Box display="flex" alignItems="center">
            <BIcon icon={IconLightBulbRound} style={{height: "32px", width: "32px"}} />
            <Box paddingLeft="16px">Important tips</Box>
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <ChevronRightIcon />
          </Box>
        </Grid>
      </Grid>
      <br/>
      <Grid item className="page-secondary-header sticky-header">
          FAQ
      </Grid>

      <Grid container  padding="0 24px" columnSpacing={{laptop: 20}}>
        { faqs.data.map((question, index) =>           
            <Grid 
              key={index}
              item 
              mobile={12} 
              laptop={6} 
              display="flex" 
              justifyContent="space-between"
              onClick={() => setCurrFaqIdx(index !== currFaqIdx ? index : null)}
            >
              <BaxiAccordion
                expanded={index === currFaqIdx}
                contextIcon={null}
                contextTitle={question.q}
              >
                <Box className="faq-answer">{question.a}</Box>
              </BaxiAccordion>
            </Grid>)
        }
        </Grid>
      </Grid>

  );
};

export default LearnComponent;
