import { SvgIcon } from "@mui/material";
import React from "react";

const IconCheck = (props: { style: React.CSSProperties }) => {
  return (
    <SvgIcon
      style={props.style}
      className={" circular-chart"}
      width="23"
      height="17"
      viewBox="0 0 23 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.24593 16.0538L0.630544 8.39231C0.353621 8.11539 0.353621 7.65385 0.630544 7.37693L1.64593 6.36154C1.92285 6.08462 2.38439 6.08462 2.66131 6.36154L8.38439 12.1308C8.56901 12.3154 8.89208 12.3154 9.0767 12.1308L20.2921 0.823079C20.569 0.546156 21.0305 0.546156 21.3075 0.823079L22.3229 1.83846C22.5998 2.11539 22.5998 2.57693 22.3229 2.85385L9.26131 16.0538C8.98439 16.3769 8.52285 16.3769 8.24593 16.0538Z"
        fill="#154898"
      />
    </SvgIcon>
  );
};

export default IconCheck;
