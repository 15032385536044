import { SvgIcon } from "@mui/material";
import React from "react";

const IconICM = (props: {
  style: React.CSSProperties;
  className: string;
  strokeClass: string;
}) => {
  return (
    <SvgIcon
      style={props.style}
      width="33"
      height="134"
      viewBox="0 0 33 134"
      fill="none"
      className={props.className}
    >
      <rect
        x="1.5"
        y="1.5"
        width="30"
        height="131"
        rx="15"
        className={props.strokeClass}
        strokeWidth="3"
      />
      <path
        className={props.className}
        d="M0 111V117.419C0 126.576 7.3873 134 16.5 134C25.6127 134 33 126.576 33 117.419V111H0Z"
        fill="url(#paint1_linear_871_2469)"
      />
      <rect
        className={props.className}
        y="21"
        width="33"
        height="12"
        fill="url(#paint2_linear_871_2469)"
      />
    </SvgIcon>
  );
};

export default IconICM;
