import { useMutation } from "@apollo/client";
import { createContext, useEffect, useState } from "react";
import { Q_DEVICE_STATUS } from "./../../data/queries/device";
import { CircularProgress } from "@mui/material";


export interface IDeviceDetails {
    batteryLevelMessage: string;
    batteryLevelPercentage: number;
    dataTransmissionMessage: string;
    deviceType: string;
    isBatteryLevelCritical: boolean;
    isDataTransmissionCritical: boolean;
    lastTransmissionTimestamp: string;
    patientPreferredName: string;
    prescribedWearTimeDays: number;
    recordingStartedAt: string;
}


export const emptyDeviceDetails: IDeviceDetails = {
    batteryLevelMessage: "",
    batteryLevelPercentage: -1,
    dataTransmissionMessage: "",
    deviceType: "",
    isBatteryLevelCritical: false,
    isDataTransmissionCritical: false,
    lastTransmissionTimestamp: "",
    patientPreferredName: "",
    prescribedWearTimeDays: -1,
    recordingStartedAt: "",
}

export const DeviceStatus = createContext<IDeviceDetails>(emptyDeviceDetails)

const DeviceDetailsContext = ({ deviceId, children }) => {


    const [deviceDetails, setDeviceDetails] = useState(emptyDeviceDetails)

    const [getDeviceStatus, { loading, error }] = useMutation(Q_DEVICE_STATUS);

    useEffect(() => {
        if(deviceId) {
            getDeviceStatus({
                variables: { deviceId: deviceId },
            }).then(res => {
                if (res?.data?.getDeviceStatus) {
                    const details = res?.data?.getDeviceStatus as IDeviceDetails       
                    setDeviceDetails({...details});
                }
            }).catch(err => {
                console.error("Failed [getDeviceStatus]", err);
            });
        }
    }, [deviceId])

    return (
        <DeviceStatus.Provider value={deviceDetails}>
            { (loading || error) ? (
                <>
                    <div className="page-header"></div>
                    <div
                        style={{
                        color: "#0C80A1",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        }}
                    >
                        {loading ? <CircularProgress /> : null}
                        {error ? "Sorry, an error has occurred" : null}
                    </div>
                </>
            ) :(children)}
        </DeviceStatus.Provider>
    )
}

export default DeviceDetailsContext;
