import { Box, Button, Grid } from "@mui/material";
import { useNavigate } from "react-router";
import BIcon from "./Icons/IconComponent";
import IconLeftArrow from "./Icons/SVGs/IconLeftArrow";
import { useContext } from "react";
import AppInfo, { IAppInfo } from "../types/AppInfo";
import PatientDetails, { IPatientDetails } from "../types/IPatientDetails";
import { DeviceStatus, IDeviceDetails } from "./shared/DeviceDetailsContext";
import moment from "moment";

const DeviceInfo = () => {
  const navigate = useNavigate();
  const appInfo = useContext<IAppInfo>(AppInfo);
  const patientDetails = useContext<IPatientDetails>(PatientDetails);
  const deviceDetails = useContext<IDeviceDetails>(DeviceStatus)


  const lableStyle = {
    opacity: 0.7,
    fontSize: "0.9rem",
    letterSpacing: "1px",
  };

  const infoStyle = {};

  function getRecordingStartedAtDate() {
    return moment(+deviceDetails.recordingStartedAt).format("MMM Do, YYYY")
  }

  return (
    <Grid container direction="column">
      <Grid item padding="24px 0 0 0" className="sticky-header">
        <Button onClick={() => navigate(-1)}>
          <BIcon
            icon={IconLeftArrow}
            style={{ height: "24px", width: "24px" }}
          />
        </Button>
      </Grid>

      <Grid item className="page-header sticky-header" sx={{ top: "68px" }}>
        Device Information
      </Grid>

      <Grid
        container
        padding="36px 24px 24px 24px"
        rowSpacing={{ mobile: 3 }}
        columnSpacing={{ laptop: 20 }}
      >
        <Grid container item direction="column">
          <Grid item style={lableStyle}>
            Device ID
          </Grid>
          <Grid item style={infoStyle} marginTop="4px">
            {patientDetails.deviceId}
          </Grid>
          <br />
          <Grid item style={lableStyle}>
            Device type
          </Grid>
          <Grid item style={infoStyle} marginTop="4px">
            {deviceDetails.deviceType}
          </Grid>
          <br />
          <Grid item style={lableStyle}>
            Manufactered
          </Grid>
          <Grid item style={infoStyle} marginTop="4px">
            Baxter
          </Grid>
          <br />
          <Grid item style={lableStyle}>
            Battery level
          </Grid>
          <Grid item style={infoStyle} marginTop="4px">
            {deviceDetails.batteryLevelPercentage}%
          </Grid>
          <br />
          <Grid item style={lableStyle}>
            Prescribed for
          </Grid>
          <Grid item style={infoStyle} marginTop="4px">
            {`${deviceDetails.prescribedWearTimeDays} days`}
          </Grid>
          <br />
          <Grid item style={lableStyle}>
            Date activated
          </Grid>
          <Grid item style={infoStyle} marginTop="4px">
            {getRecordingStartedAtDate()}
          </Grid>
          <br />
          {/* <Grid item style={lableStyle}>
            Device model
          </Grid>
          <Grid item style={infoStyle} marginTop="4px">
            Bardy DX MCT
          </Grid>
          <br /> */}
        </Grid>
      </Grid>
    </Grid>
  );
};



export default DeviceInfo;
