import { SvgIcon } from "@mui/material";
import React from "react";

const IconSquaredCross = (props: { style: React.CSSProperties }) => {
  return (
    <SvgIcon style={props.style}>
      <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.875 13.75H10.125V10.125H13.75V7.875H10.125V4.25H7.875V7.875H4.25V10.125H7.875V13.75ZM2.30775 17.5C1.80258 17.5 1.375 17.325 1.025 16.975C0.675 16.625 0.5 16.1974 0.5 15.6923V2.30775C0.5 1.80258 0.675 1.375 1.025 1.025C1.375 0.675 1.80258 0.5 2.30775 0.5H15.6923C16.1974 0.5 16.625 0.675 16.975 1.025C17.325 1.375 17.5 1.80258 17.5 2.30775V15.6923C17.5 16.1974 17.325 16.625 16.975 16.975C16.625 17.325 16.1974 17.5 15.6923 17.5H2.30775ZM2.30775 16H15.6923C15.7692 16 15.8398 15.9679 15.9038 15.9038C15.9679 15.8398 16 15.7692 16 15.6923V2.30775C16 2.23075 15.9679 2.16025 15.9038 2.09625C15.8398 2.03208 15.7692 2 15.6923 2H2.30775C2.23075 2 2.16025 2.03208 2.09625 2.09625C2.03208 2.16025 2 2.23075 2 2.30775V15.6923C2 15.7692 2.03208 15.8398 2.09625 15.9038C2.16025 15.9679 2.23075 16 2.30775 16Z"
          fill="#484C4E"
        />
      </svg>
    </SvgIcon>
  );
};

export default IconSquaredCross;
