import { SvgIcon } from "@mui/material";
import React from "react";

const IconCheckedList = (props: { style: React.CSSProperties }) => {
  return (
    <SvgIcon style={props.style}>
      <svg
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.74334 27.3333C5.06979 27.3333 4.49967 27.1 4.03301 26.6333C3.56634 26.1667 3.33301 25.5965 3.33301 24.923V7.07699C3.33301 6.40343 3.56634 5.83332 4.03301 5.36666C4.49967 4.89999 5.06979 4.66666 5.74334 4.66666H26.256C26.9296 4.66666 27.4997 4.89999 27.9663 5.36666C28.433 5.83332 28.6663 6.40343 28.6663 7.07699V24.923C28.6663 25.5965 28.433 26.1667 27.9663 26.6333C27.4997 27.1 26.9296 27.3333 26.256 27.3333H5.74334ZM5.74334 25.3333H26.256C26.3587 25.3333 26.4527 25.2905 26.538 25.205C26.6236 25.1197 26.6663 25.0257 26.6663 24.923V7.07699C26.6663 6.97432 26.6236 6.88032 26.538 6.79499C26.4527 6.70943 26.3587 6.66666 26.256 6.66666H5.74334C5.64067 6.66666 5.54667 6.70943 5.46134 6.79499C5.37579 6.88032 5.33301 6.97432 5.33301 7.07699V24.923C5.33301 25.0257 5.37579 25.1197 5.46134 25.205C5.54667 25.2905 5.64067 25.3333 5.74334 25.3333ZM6.99967 22.3333H12.9997V20.3333H6.99967V22.3333ZM19.3997 19.5257L25.5253 13.4L24.0997 11.9743L19.3997 16.7077L17.4997 14.8077L16.1073 16.2333L19.3997 19.5257ZM6.99967 17H12.9997V15H6.99967V17ZM6.99967 11.6667H12.9997V9.66666H6.99967V11.6667Z"
          fill="#154898"
        />
      </svg>
    </SvgIcon>
  );
};

export default IconCheckedList;
