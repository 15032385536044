import { Button, Grid } from "@mui/material";
import React, { useContext, useState } from "react";
import BIcon from "./Icons/IconComponent";
import { ICONS } from "./Icons/IconComponentMapping";
import { useNavigate } from "react-router-dom";
import PatientDetails, { IPatientDetails } from "../types/IPatientDetails";
import { UnauthenticatedTemplate } from "@azure/msal-react";
import messages from "./../data/UserMessages.json";
import GeneralLoginComponent from "./login/GeneralLoginComponent";
import { DeviceStatus, IDeviceDetails } from "./shared/DeviceDetailsContext";
import { calculateWearTimeRemaining, wrapWordsInBold } from "./utilityFunctions";
import SessionStorageUtility from "./utilities/SessionStorageUtility";
import { FORCED_APP_STATE } from "../constants/forcedAppStates";

enum enumHomeState {
  "ACTIVE_MONITORING" = "ACTIVE_MONITORING",
  "NO_TRANSMISSION" = "NO_TRANSMISSION",
  "NO_ACTIVE_DEVICE" = "NO_ACTIVE_DEVICE",
  "RETURN_DEVICE" = "RETURN_DEVICE",
  "SWITCH_PATCH" = "SWITCH_PATCH",
}
const HomeComponent = () => {

  const [hasForcedHomeState, setHasForcedHomeState] = useState(false);

  const device = React.useContext<IPatientDetails>(PatientDetails);
  const [currentHomeState, setCurrentHomeState] = React.useState(null);
  const deviceDetails = useContext<IDeviceDetails>(DeviceStatus);

  React.useEffect(() => getcurrentHomeState(), [device, deviceDetails]);
  const navigate = useNavigate();
  const getcurrentHomeState = () => {

    const forcedState = SessionStorageUtility.getForcedAppState()
    setHasForcedHomeState(!!forcedState)

    switch (forcedState) {
      case FORCED_APP_STATE.NO_ACTIVE_DEVICE:
        setCurrentHomeState(enumHomeState.NO_ACTIVE_DEVICE);
        return;
      case FORCED_APP_STATE.NO_TRANSMISSION:
        setCurrentHomeState(enumHomeState.NO_TRANSMISSION);
        return;
    }

    const { recordingStartedAt, prescribedWearTimeDays } = deviceDetails;
    const daysLeft = calculateWearTimeRemaining(recordingStartedAt, prescribedWearTimeDays);

    if (device.deviceId && daysLeft < 1) {
      setCurrentHomeState(enumHomeState.RETURN_DEVICE);
      return;
    }
    
    const { isBatteryLevelCritical, isDataTransmissionCritical } = deviceDetails;
    
    if (device.deviceId && !device.mappedToPatient && !SessionStorageUtility.getStoredUnmappedDeviceId()) {
      setCurrentHomeState(enumHomeState.NO_ACTIVE_DEVICE);
      return;
    }
    if (!isBatteryLevelCritical && !isDataTransmissionCritical) {
      setCurrentHomeState(enumHomeState.ACTIVE_MONITORING);
    } else if (isBatteryLevelCritical) {
      setCurrentHomeState(enumHomeState.SWITCH_PATCH);
    } else if (isDataTransmissionCritical) {
      setCurrentHomeState(enumHomeState.NO_TRANSMISSION);
    }
    
  };
  return (
    <>
      {!hasForcedHomeState && !device?.deviceId && !device?.mappedToPatient ? (
        <UnauthenticatedTemplate>
          <GeneralLoginComponent />
        </UnauthenticatedTemplate>
      ) : (
        <Grid
          container
          spacing={0}
          direction="column"
          style={{
            minHeight: "calc(100vh - 100px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
            overflow: "hidden",
          }}
        >
          <Grid item mobile={12} laptop={6} style={{ background: "white" }}>
            <Grid container style={{ maxWidth: "600px", display: "flex" }}>
              <Grid item mobile={12} laptop={12} className="page-header">
                {messages.home.txtHomeHeader} {device?.firstName}
              </Grid>
              <Grid
                item
                mobile={12}
                laptop={12}
                padding="0 20px 0 20px"
                className="home-welcome-desc"
              >
                {messages.home[currentHomeState]?.txtHomeHeaderDesc.map(msg => (
                  <div
                    className="desc"
                    dangerouslySetInnerHTML={wrapWordsInBold(
                      msg,
                      messages.general.highlights
                    )}
                  ></div>
                ))}
              </Grid>
              <Grid
                item
                mobile={12}
                laptop={12}
                style={{ marginTop: "20px", display: "flex" }}
                sx={{
                  paddingLeft: { mobile: "0px", laptop: "20px" },
                  justifyContent: {
                    mobile:
                      currentHomeState === enumHomeState.NO_TRANSMISSION ||
                      currentHomeState === enumHomeState.SWITCH_PATCH ||
                      currentHomeState === enumHomeState.NO_ACTIVE_DEVICE ||
                      currentHomeState === enumHomeState.RETURN_DEVICE
                        ? "center"
                        : "inherit",
                    laptop: "inherit",
                  },
                }}
              >
                {currentHomeState in enumHomeState ? (
                  <BIcon
                    icon={ICONS[currentHomeState]}
                    style={{
                      verticalAlign: "bottom",
                      width: "auto",
                      height: "200px",
                    }}
                  />
                ) : (
                  <>No Image</>
                )}
              </Grid>
              <Grid
                item
                mobile={12}
                laptop={12}
                style={{ display: "flex", marginTop: "32px" }}
                sx={{
                  paddingLeft: { mobile: "0px", laptop: "20px" },
                  justifyContent: { mobile: "center", laptop: "inherit" },
                }}
              >
                {currentHomeState === enumHomeState.NO_TRANSMISSION ||
                currentHomeState === enumHomeState.NO_ACTIVE_DEVICE ? null : (
                  <Button
                    variant="outlined"
                    sx={{ width: "80%" }}
                    className="baxi-button baxi-primary-button"
                    onClick={() => {
                      navigate(messages.home[currentHomeState]?.actionDestination);
                    }}
                  >
                    {messages.home[currentHomeState]?.actionBtnTxt}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default HomeComponent;
