import { SvgIcon } from "@mui/material";
import React from "react";

const IconHomeOutline = (props: { style: React.CSSProperties }) => {
  return (
    <SvgIcon
      style={props.style}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      x="0px"
      y="0px"
      fill="none"
    >
      <path
        fill="none"
        d="M17.8424 5.63287L17.8432 5.63347L25.8438 11.8535C25.8441 11.8537 25.8444 11.8539 25.8446 11.8541C26.2045 12.135 26.4956 12.4943 26.6958 12.9045C26.8961 13.3149 27.0001 13.7658 27 14.2227V14.2229L27 23.9998L27 24.0014C27.0006 24.3954 26.9235 24.7858 26.7731 25.15C26.6227 25.5144 26.4021 25.8455 26.1237 26.1243C25.8453 26.4031 25.5146 26.6243 25.1505 26.7753L25.5335 27.699L25.1505 26.7753C24.7864 26.9263 24.3963 27.004 24.0022 27.004H8.00427C7.20749 27.004 6.4433 26.6875 5.87991 26.1241C5.31651 25.5607 5 24.7965 5 23.9998L5 14.2229L5 14.2224C4.99975 13.7656 5.10417 13.3147 5.30522 12.9044C5.50628 12.4941 5.79855 12.1353 6.15968 11.8556L6.1614 11.8543L14.1573 5.63324L14.1578 5.63287C14.6843 5.22271 15.3326 5 16 5C16.6675 5 17.3159 5.22273 17.8424 5.63287Z"
        stroke="#0C80A1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 21H20"
        stroke="#0C80A1"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default IconHomeOutline;
