import { SvgIcon } from "@mui/material";
import React from "react";

const IconMyInfoOutline = (props: {
  style: React.CSSProperties;
  className: string;
}) => {
  return (
    <SvgIcon
      style={props.style}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      className={props.className}
      x="0px"
      y="0px"
    >
      <path
        fill="none"
        d="M5 26.5714V25.1429C5 23.4991 6.13649 21.9836 8.23405 20.8233C10.3114 19.6741 13.139 19 16 19C18.8584 19 21.6859 19.6837 23.7644 20.8384C25.8658 22.0059 27 23.5222 27 25.1429V26.5714C27 26.669 26.9598 26.7728 26.8711 26.8573C26.7805 26.9436 26.6478 27 26.5 27H5.5C5.35222 27 5.21947 26.9436 5.12893 26.8573C5.04017 26.7728 5 26.669 5 26.5714Z"
        stroke="#0C80A1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="none"
        d="M21 10C21 12.7614 18.7614 15 16 15C13.2386 15 11 12.7614 11 10C11 7.23858 13.2386 5 16 5C18.7614 5 21 7.23858 21 10Z"
        stroke="#0C80A1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default IconMyInfoOutline;
