import { SvgIcon } from "@mui/material";
import React from "react";

const IconMyInfoFilled = (props: {
  style: React.CSSProperties;
  className: string;
  color: string;
}) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={props.className}
      x="0px"
      y="0px"
      style={{ verticalAlign: "middle" }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4999 7C17.4999 3.6 14.9999 0.900002 11.9999 0.900002C8.9999 0.900002 6.4999 3.6 6.4999 7C6.4999 10.4 8.9999 13.1 11.9999 13.1C14.9999 13.1 17.4999 10.4 17.4999 7ZM23.0999 20.9V19.8C23.0999 17.1 19.8999 15.5 16.8999 14.2L16.5999 14.1C16.3999 14 16.1999 14 15.8999 14.1C14.7999 14.9 13.4999 15.3 11.9999 15.3C10.4999 15.3 9.1999 14.9 7.9999 14.1C7.7999 14 7.4999 14 7.2999 14.1L6.9999 14.2C4.0999 15.5 0.899902 17.2 0.899902 19.8V20.9C0.899902 22.1 1.8999 23.1 3.0999 23.1H20.8999C22.0999 23.1 23.0999 22.1 23.0999 20.9Z"
        fill={props.color}
      />
    </SvgIcon>
  );
};

export default IconMyInfoFilled;
