import { SvgIcon } from "@mui/material";
import React from "react";

const IconHomeFilled = (props: {
  style: React.CSSProperties;
  className: string;
  color: string;
}) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={props.className}
      style={{ verticalAlign: "middle" }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.617 12.4615H20.3093V22.6154C20.3093 22.8923 20.1247 23.0769 19.8478 23.0769H15.2324C14.9555 23.0769 14.7709 22.8923 14.7709 22.6154V14.7692H9.23241V22.6154C9.23241 22.8923 9.0478 23.0769 8.77087 23.0769H4.15549C3.87857 23.0769 3.69395 22.8923 3.69395 22.6154V12.4615H1.38626C1.20164 12.4615 1.01703 12.3692 0.970875 12.1846C0.878568 12 0.924722 11.8154 1.06318 11.6769L11.6786 1.06154C11.8632 0.876927 12.1863 0.876927 12.3247 1.06154L22.9401 11.6769C23.0786 11.8154 23.0786 12 23.0324 12.1846C22.9863 12.3692 22.8016 12.4615 22.617 12.4615Z"
        fill={props.color}
      />
    </SvgIcon>
  );
};

export default IconHomeFilled;
