import { SvgIcon } from "@mui/material";
import React from "react";

const IconLearnFilled = (props: {
  style: React.CSSProperties;
  className: string;
  color: string;
}) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={props.className}
      style={{ verticalAlign: "middle" }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9998 0.92308C5.86131 0.92308 0.922852 5.86154 0.922852 12C0.922852 18.1385 5.86131 23.0769 11.9998 23.0769C18.1382 23.0769 23.0767 18.1385 23.0767 12C23.0767 5.86154 18.1382 0.92308 11.9998 0.92308ZM11.9998 6.5077C12.7844 6.5077 13.3844 7.1077 13.3844 7.89231C13.3844 8.67693 12.7844 9.27693 11.9998 9.27693C11.2152 9.27693 10.6152 8.67693 10.6152 7.89231C10.6152 7.1077 11.2152 6.5077 11.9998 6.5077ZM14.3075 16.2C14.3075 16.4308 14.1229 16.6154 13.8459 16.6154H10.1536C9.92285 16.6154 9.69208 16.4769 9.69208 16.2V15.2769C9.69208 15.0462 9.8767 14.7692 10.1536 14.7692C10.3844 14.7692 10.6152 14.6308 10.6152 14.3538V12.5077C10.6152 12.2769 10.4305 12 10.1536 12C9.92285 12 9.69208 11.8615 9.69208 11.5846V10.6615C9.69208 10.4308 9.8767 10.1538 10.1536 10.1538H12.9229C13.1536 10.1538 13.3844 10.3846 13.3844 10.6615V14.3538C13.3844 14.5846 13.569 14.7692 13.8459 14.7692C14.0767 14.7692 14.3075 15 14.3075 15.2769V16.2Z"
        fill={props.color}
      />
    </SvgIcon>
  );
};

export default IconLearnFilled;
