import { SvgIcon } from "@mui/material";
import React from "react";

const IconMovieClapperboard = (props: { style: React.CSSProperties }) => {
  return (
    <SvgIcon style={props.style}>
      <svg
        viewBox="0 0 26 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.74367 0L5.077 4.66667H9.077L6.74367 0H9.41033L11.7437 4.66667H15.7437L13.4103 0H16.077L18.4103 4.66667H22.4103L20.077 0H23.2563C23.9299 0 24.5 0.233333 24.9667 0.7C25.4333 1.16667 25.6667 1.73678 25.6667 2.41033V17.5897C25.6667 18.2632 25.4333 18.8333 24.9667 19.3C24.5 19.7667 23.9299 20 23.2563 20H2.74367C2.07011 20 1.5 19.7667 1.03333 19.3C0.566666 18.8333 0.333333 18.2632 0.333333 17.5897V2.41033C0.333333 1.73678 0.566666 1.16667 1.03333 0.7C1.5 0.233333 2.07011 0 2.74367 0ZM2.33333 6.66667V17.5897C2.33333 17.7094 2.37178 17.8078 2.44867 17.8847C2.52556 17.9616 2.62389 18 2.74367 18H23.2563C23.3761 18 23.4744 17.9616 23.5513 17.8847C23.6282 17.8078 23.6667 17.7094 23.6667 17.5897V6.66667H2.33333Z"
          fill="#154898"
        />
      </svg>
    </SvgIcon>
  );
};

export default IconMovieClapperboard;
