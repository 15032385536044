import React from "react";
import { NavLink } from "react-router-dom";
import BIcon from "./Icons/IconComponent";
import { ICONS } from "./Icons/IconComponentMapping";
import { generateID } from "./utilityFunctions";
import { Typography } from "@mui/material";
import { APP_ROUTES } from "../constants/appRoutes";
import useOnlineStatus from "./hooks/useOnlineStatus";

export const navbarList = [
  {
    link: APP_ROUTES.ROOT,
    iconComponent: "IconHomeFilled",
    label: "Home",
    id: "home",
  },
  {
    link: APP_ROUTES.HISTORY,
    iconComponent: "IconSymptomsHistoryFilled",
    label: "History",
    id: "history",
  },
  {
    link: APP_ROUTES.MY_INFO,
    iconComponent: "IconMyInfoFilled",
    label: "My Info",
    id: "my-info",
  },
  {
    link: APP_ROUTES.LEARN,
    iconComponent: "IconLearnFilled",
    label: "Learn",
    id: "learn",
  },
];

const Navbar = () => {
  const isOnline = useOnlineStatus();
  return (
    <div className="footer overall-background">
      <ul>
        {navbarList.map((navbarItem, navbarItemIndex) => {
          return (
            <NavLink
              to={isOnline ? navbarItem.link : null}
              key={navbarItemIndex}
            >
              {({ isActive }) => (
                <li
                  style={{ opacity: isOnline ? 1 : 0.5 }}
                  id={generateID("li", "navbar", navbarItem.id)}
                >
                  <div
                    className={
                      isActive && isOnline
                        ? "navbar-segment icon-container"
                        : ""
                    }
                  >
                    <BIcon
                      class="navigation-icon"
                      fillColor={isActive && isOnline ? "#FFFFFF" : "#484C4E"}
                      icon={ICONS[navbarItem.iconComponent]}
                    />
                  </div>
                  <Typography
                    id={generateID("txt", "navbar", navbarItem.id + "-label")}
                    style={{ marginTop: "4px" }}
                    className={`common-navbar-icon-text ${
                      isActive && isOnline
                        ? "active-navbar-icon-text"
                        : "inactive-navbar-icon-text"
                    }`}
                  >
                    {" "}
                    {navbarItem.label}{" "}
                  </Typography>
                </li>
              )}
            </NavLink>
          );
        })}
      </ul>
    </div>
  );
};

export default Navbar;
