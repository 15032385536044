import { Box, Button, Grid } from "@mui/material";
import { useNavigate } from "react-router";
import BIcon from "./Icons/IconComponent";
import IconLeftArrow from "./Icons/SVGs/IconLeftArrow";

const DataPolicy = () => {
  const navigate = useNavigate();

  return (
    <Grid container direction="column">
      <Grid item padding="24px 0 0 0" className="sticky-header">
        <Button onClick={() => navigate(-1)}>
          <BIcon
            icon={IconLeftArrow}
            style={{ height: "24px", width: "24px" }}
          />
        </Button>
      </Grid>

      <Grid item className="page-header sticky-header" sx={{ top: "68px" }}>
        Privacy policies
      </Grid>

      <Grid
        container
        padding="36px 24px 24px 24px"
        rowSpacing={{ mobile: 3 }}
        columnSpacing={{ laptop: 20 }}
      >
        Ac molestie aliquet posuere tortor porta nunc ultricies. Purus elit
        vitae adipiscing gravida at adipiscing odio sollicitudin sit. A dictum
        condimentum pretium, nunc. Ac molestie aliquet posuere tortor porta nunc
        ultricies. Purus elit vitae adipiscing gravida at adipiscing odio
        sollicitudin sit. A dictum condimentum pretium, nunc. Id facilisis sed
        viverra enim sed. Ac auctor ante egestas neque sit ipsum, viverra. Lorem
        ipsum dolor sit amet, consectetur adipiscing elit. Donec neque, enim
        mauris scelerisque pretium morbi. <br /> <br />Ac molestie aliquet posuere tortor
        porta nunc ultricies. Purus elit vitae adipiscing gravida at adipiscing
        odio sollicitudin sit. A dictum condimentum pretium, nunc. Ac molestie
        aliquet posuere tortor porta nunc ultricies. Purus elit vitae adipiscing
        gravida at adipiscing odio sollicitudin sit. A dictum condimentum
        pretium, nunc.  <br /> <br />Ac molestie aliquet posuere tortor porta nunc ultricies.
        Purus elit vitae adipiscing gravida at adipiscing odio sollicitudin sit.
        A dictum condimentum pretium, nunc. Id facilisis sed viverra enim sed.
        Ac auctor ante egestas neque sit ipsum, viverra. Lorem ipsum dolor sit
        amet, consectetur adipiscing elit. Donec neque, enim mauris scelerisque
        pretium morbi. Ac molestie aliquet posuere tortor porta nunc ultricies.
        Purus elit vitae adipiscing gravida at adipiscing odio sollicitudin sit.
        A dictum condimentum pretium, nunc.
      </Grid>
    </Grid>
  );
};

export default DataPolicy;
