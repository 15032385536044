import { Box, Button, Grid } from "@mui/material";
import { useNavigate } from "react-router";
import BIcon from "./Icons/IconComponent";
import IconLeftArrow from "./Icons/SVGs/IconLeftArrow";
import IconTechSupport from "./Icons/SVGs/IconTechSupport";

const TechSupport = () => {
  const navigate = useNavigate();

  return (
    <Grid container direction="column" height="100%">
      <Grid container item direction="column">
        <Grid item padding="24px 0 0 0" className="sticky-header">
          <Button onClick={() => navigate(-1)}>
            <BIcon
              icon={IconLeftArrow}
              style={{ height: "24px", width: "24px" }}
            />
          </Button>
        </Grid>

        <Grid item className="page-header sticky-header" sx={{ top: "68px" }}>
          Tech Support
        </Grid>
      </Grid>

      <Grid container item direction="column">
        <Grid
          container
          item
          direction="column"
          alignItems="center"
          padding="0 24px"
          sx={{ alignItems: "start" }}
        >
          <Box sx={{ marginTop: "24px", wordSpacing: "1px" }}>
            Any Questions? Visit the Learn page or call our 24/7 Customer Service Team:
          </Box>
          <Grid item container direction="row" justifyContent="center">
            <Box textAlign="start" sx={{ marginTop: "32px", wordSpacing: "1px" }}>
              <b>1- 844 - 777 - 9283 </b>
            </Box>
          </Grid>
        </Grid>
        <Grid container item direction="row" justifyContent="center" marginTop="24px">
          <BIcon
            icon={IconTechSupport}
            style={{
              height: "200px",
              minWidth: "100%",
              width: "100%",
              fill: "none",
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TechSupport;
