import { SvgIcon } from "@mui/material";
import React from "react";

const IconSymptomsHistoryOutline = (props: {
  style: React.CSSProperties;
  className: string;
}) => {
  return (
    <SvgIcon
      style={props.style}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      className={props.className}
      x="0px"
      y="0px"
    >
      <mask id="path-1-inside-1_871_2499" fill="white">
        <path d="M11.407 4.91334C13.5999 4.00483 16.0128 3.76768 18.3408 4.2307C20.6688 4.69372 22.8071 5.83652 24.4855 7.51498C26.1638 9.19344 27.3067 11.3321 27.7696 13.6602C28.2325 15.9883 27.9946 18.4007 27.086 20.5935C26.1774 22.7864 24.6389 24.6615 22.6652 25.9799C20.6914 27.2983 18.371 28.0005 15.9974 28C13.6239 27.9995 11.3037 27.2962 9.33052 25.977C7.35731 24.6577 5.81965 22.7824 4.912 20.5891C3.69529 17.649 3.69604 14.3467 4.91402 11.4072C6.13199 8.46764 8.4675 6.13121 11.407 4.91334Z" />
      </mask>
      <path
        d="M10.6415 3.06564C9.62105 3.48842 9.13655 4.6584 9.55933 5.67885C9.98211 6.69931 11.1521 7.18382 12.1725 6.76103L10.6415 3.06564ZM12.1725 6.76103C13.193 6.33825 13.6775 5.16827 13.2547 4.14782C12.8319 3.12737 11.662 2.64286 10.6415 3.06564L12.1725 6.76103ZM12.1725 6.76103C13.9995 6.00409 16.0103 5.80635 17.9507 6.19228L18.731 2.26912C16.0153 1.729 13.2002 2.00557 10.6415 3.06564L12.1725 6.76103ZM17.9507 6.19228C19.8907 6.57815 21.6727 7.53049 23.0712 8.92914L25.8997 6.10083C23.9416 4.14254 21.4468 2.8093 18.731 2.26912L17.9507 6.19228ZM23.0712 8.92914C24.4698 10.3278 25.4222 12.1101 25.808 14.0502L29.7312 13.2702C29.1912 10.5541 27.8578 8.05905 25.8997 6.10083L23.0712 8.92914ZM25.808 14.0502C26.1937 15.9902 25.9955 18.0005 25.2383 19.8279L28.9336 21.3591C29.9936 18.8008 30.2712 15.9863 29.7312 13.2702L25.808 14.0502ZM25.2383 19.8279C24.481 21.6557 23.1988 23.2184 21.5543 24.3168L23.7761 27.643C26.0791 26.1047 27.8738 23.917 28.9336 21.3591L25.2383 19.8279ZM21.5543 24.3168C19.9101 25.4151 17.9765 26.0004 15.9979 26L15.997 30C18.7655 30.0006 21.4727 29.1815 23.7761 27.643L21.5543 24.3168ZM15.9979 26C14.0192 25.9996 12.0859 25.4134 10.4421 24.3144L8.21892 27.6396C10.5215 29.1791 13.2285 29.9994 15.997 30L15.9979 26ZM10.4421 24.3144C8.79796 23.2151 7.51647 21.6523 6.76001 19.8243L3.064 21.3539C4.12283 23.9125 5.91667 26.1004 8.21892 27.6396L10.4421 24.3144ZM6.76001 19.8243C5.74607 17.3743 5.74669 14.6224 6.76169 12.1728L3.06634 10.6416C1.64539 14.0711 1.6445 17.9238 3.064 21.3539L6.76001 19.8243ZM6.76169 12.1728C7.7768 9.72282 9.72324 7.7758 12.1725 6.76103L10.6415 3.06564C7.21177 4.48661 4.48718 7.21246 3.06634 10.6416L6.76169 12.1728Z"
        fill="#0C80A1"
        mask="url(#path-1-inside-1_871_2499)"
      />
      <path
        d="M16 9.88232V16.9019L19.8039 20.7059"
        stroke="#0C80A1"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default IconSymptomsHistoryOutline;
