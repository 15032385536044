import { Box, Button, Grid } from "@mui/material";
import { useNavigate } from "react-router";
import BIcon from "./Icons/IconComponent";
import IconLeftArrow from "./Icons/SVGs/IconLeftArrow";
import { useContext } from "react";
import AppInfo, { IAppInfo } from "../types/AppInfo";

const BuildInfo = () => {
  const navigate = useNavigate();
  const appInfo = useContext<IAppInfo>(AppInfo);

  const lableStyle = {
    opacity: 0.7,
    fontSize: "0.9rem",
    letterSpacing: "1px"
  };

  const infoStyle = {
  };

  return (
    <Grid container direction="column">
      <Grid item padding="24px 0 0 0" className="sticky-header">
        <Button onClick={() => navigate(-1)}>
          <BIcon
            icon={IconLeftArrow}
            style={{ height: "24px", width: "24px" }}
          />
        </Button>
      </Grid>

      <Grid item className="page-header sticky-header" sx={{ top: "68px" }}>
        Build Number
      </Grid>

      <Grid
        container
        padding="36px 24px 24px 24px"
        rowSpacing={{ mobile: 3 }}
        columnSpacing={{ laptop: 20 }}
      >
        <Grid container item direction="column">
          <Grid item style={lableStyle}>
            Version
          </Grid>
          <Grid item style={infoStyle} marginTop="4px">
            {appInfo.buildVersion}
          </Grid>
        </Grid>
        <Grid container item direction="column" sx={{wordSpacing: "2px"}}>
          <Grid item style={lableStyle}>
            ©Copyright 2024 Baxter.
          </Grid>
          <Grid item style={lableStyle}>
            All rights reserved.
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BuildInfo;
