import { useQuery } from "@apollo/client";
import { useMsal } from "@azure/msal-react";
import PatientDetails, { IPatientDetails } from "../types/IPatientDetails";
import { useEffect, useState } from "react";
import { Outlet } from "react-router";
import { Q_DEVICE_FROM_USER } from "./../data/queries/device";
import { useSearchParams } from "react-router-dom";
import DeviceUtility from "./utilities/DeviceUtility";
import DeviceDetailsContext from "./shared/DeviceDetailsContext";
import SessionStorageUtility from "./utilities/SessionStorageUtility";

const Main = () => {
  const { instance } = useMsal();
  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }
  const { data } = useQuery(Q_DEVICE_FROM_USER, {
    variables: { email: activeAccount?.idTokenClaims?.email?.toLowerCase() },
    skip: !activeAccount?.idTokenClaims?.email,
  });

  const [device, setDevice] = useState<IPatientDetails>(DeviceUtility.createEmptyDevice());
  const [searchParams] = useSearchParams();

  const getUnmappedDeviceId = () => {
    return searchParams.get("deviceId")
    ? searchParams.get("deviceId")
    : SessionStorageUtility.getStoredUnmappedDeviceId();
  }

  useEffect(() => {
    const deviceIdParam = getUnmappedDeviceId()
      
    if (
      !activeAccount &&
      deviceIdParam &&
      DeviceUtility.validDeviceId(deviceIdParam)
    ) {
      const d = DeviceUtility.createEmptyDevice();
      d.deviceId = deviceIdParam;
      setDevice(d);
      SessionStorageUtility.storeUnmappedDeviceId(deviceIdParam);
    }

    if (data) {
      const d = DeviceUtility.createEmptyDevice();
      d.deviceId = data?.patient?.devices[0]?.deviceId;
      d.email = activeAccount?.idTokenClaims?.email?.toLowerCase();
      d.firstName = activeAccount?.idTokenClaims?.given_name;
      d.lastName = activeAccount?.idTokenClaims?.family_name;
      d.mappedToPatient = true;
      setDevice(d);
    }
  }, [data, searchParams]);
  return (
    <PatientDetails.Provider value={device}>
      <DeviceDetailsContext deviceId={device.deviceId}>
        <Outlet />
      </DeviceDetailsContext>
    </PatientDetails.Provider>
  );
};

export default Main;
