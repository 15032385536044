import React from "react";
import BIcon from "./Icons/IconComponent";
import { ICONS } from "./Icons/IconComponentMapping";
import { NavLink } from "react-router-dom";
import { navbarList } from "./Navbar";

const Sidebar = () => {
  return (
    <>
      <div>
        <ul className="sidebar overall-background">
          <li>
            <BIcon style={{ width: "150px" }} icon={ICONS["CompanyLogo"]} />
          </li>
          {navbarList.map((navbarItem, navbarItemIndex) => {
            return (
              <NavLink to={navbarItem.link} key={navbarItemIndex}>
                {({ isActive }) => (
                  <li
                    className={`sidebar-item ${isActive ? "active-sidebar-item" : "inactive-sidebar-item"}`}
                  >
                    <span style={{ marginRight: "10px" }}>
                      <BIcon icon={ICONS[navbarItem.iconComponent]} />
                    </span>
                    <span>{navbarItem.label}</span>
                  </li>
                )}
              </NavLink>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
