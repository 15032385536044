import React from "react";
interface IIcon {
  id?: string;
  title?: string;
  style?: React.CSSProperties;
  class?: string;
  onClick?: any;
  strokeClass?: string;
  icon?: any;
  fillColor?: string;
}
const BIcon = (props: IIcon) => {
  const IconComponent = props.icon;
  return (
    <span title={props.title}>
      {IconComponent ? (
        <IconComponent
          id={props.id}
          style={Object.assign({}, props.style)}
          className={props.class}
          onClick={props.onClick}
          strokeClass={props.strokeClass}
          color={props.fillColor}
        />
      ) : (
        <>Image not found</>
      )}
    </span>
  );
};

export default BIcon;
