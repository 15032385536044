import { Box, Button, Grid } from "@mui/material";
import IconLeftArrow from "./Icons/SVGs/IconLeftArrow";
import BIcon from "./Icons/IconComponent";
import howToSteps from "../data/howToReturnDevice.json";
import { useNavigate } from "react-router";

const HowToReturnDevice = () => {
  const navigate = useNavigate();

  return (
    <Grid container direction="column">
      <Grid item padding="24px 0 0 0" className="sticky-header" >
        <Button onClick={() => navigate(-1)}>
            <BIcon icon={IconLeftArrow} style={{ height: "24px", width: "24px" }}/>
        </Button>
      </Grid>

      <Grid item className="page-header sticky-header" sx={{top: "68px"}}>
        It is time to return your device
      </Grid>

      <Grid
        container
        padding="24px"
        rowSpacing={{ mobile: 3 }}
        columnSpacing={{ laptop: 20 }}
      >
        {howToSteps.data.map((instruction, index) => (
          <>
            <Grid container item>
              <Box display="flex" alignItems="flex-start">
                <Box
                  sx={{
                    fontFamily: "Geogrotesque",
                    fontWeight: "bold",
                    fontSize: "20px",
                    color: "#154898",
                  }}
                >
                  {index + 1}.
                </Box>
                <Box
                  sx={{
                    wordSpacing: "1px",
                    marginLeft: "16px",
                    fontSize: "1.1rem",
                  }}
                >
                  {instruction}
                </Box>
              </Box>
            </Grid>
          </>
        ))}
      </Grid>
    </Grid>
  );
};

export default HowToReturnDevice;
