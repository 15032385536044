import { SvgIcon } from "@mui/material";
import React from "react";

const IconReadingHead = (props: { style: React.CSSProperties }) => {
  return (
    <SvgIcon style={props.style}>
      <svg
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 29.077C14.4513 27.711 12.7078 26.6516 10.7693 25.8987C8.83089 25.1458 6.79667 24.7351 4.66667 24.6667V11.1027C6.80867 11.1624 8.85522 11.6086 10.8063 12.441C12.7577 13.2734 14.4889 14.388 16 15.7847C17.5111 14.388 19.2423 13.2734 21.1937 12.441C23.1448 11.6086 25.1913 11.1624 27.3333 11.1027V24.6667C25.1898 24.7351 23.1521 25.1458 21.2203 25.8987C19.2888 26.6516 17.5487 27.711 16 29.077ZM16 26.5077C17.4 25.4719 18.8889 24.641 20.4667 24.015C22.0444 23.389 23.6667 22.9754 25.3333 22.7743V13.318C23.6513 13.6069 22.0227 14.1796 20.4473 15.036C18.8722 15.8922 17.3898 17.0152 16 18.405C14.6102 17.0152 13.1278 15.8922 11.5527 15.036C9.97733 14.1796 8.34867 13.6069 6.66667 13.318V22.7743C8.33333 22.9754 9.95555 23.389 11.5333 24.015C13.1111 24.641 14.6 25.4719 16 26.5077ZM16 11.718C14.6744 11.718 13.5397 11.246 12.5957 10.302C11.6517 9.358 11.1797 8.22311 11.1797 6.89733C11.1797 5.57178 11.6517 4.437 12.5957 3.493C13.5397 2.549 14.6744 2.077 16 2.077C17.3256 2.077 18.4603 2.549 19.4043 3.493C20.3483 4.437 20.8203 5.57178 20.8203 6.89733C20.8203 8.22311 20.3483 9.358 19.4043 10.302C18.4603 11.246 17.3256 11.718 16 11.718ZM16.0003 9.718C16.7761 9.718 17.4401 9.44178 17.9923 8.88933C18.5446 8.33689 18.8207 7.67278 18.8207 6.897C18.8207 6.12122 18.5444 5.45722 17.992 4.905C17.4396 4.353 16.7754 4.077 15.9997 4.077C15.2239 4.077 14.5599 4.35322 14.0077 4.90567C13.4554 5.45811 13.1793 6.12222 13.1793 6.898C13.1793 7.67378 13.4556 8.33767 14.008 8.88967C14.5604 9.44189 15.2246 9.718 16.0003 9.718Z"
          fill="#154898"
        />
      </svg>
    </SvgIcon>
  );
};

export default IconReadingHead;
