import { SvgIcon } from "@mui/material";
import React from "react";

const IconSuccess = (props: { style: React.CSSProperties }) => {
  return (
    <SvgIcon
      style={props.style}
      viewBox="0 0 150 150"
      className={" circular-chart"}
    >
      <svg
        width="150"
        height="150"
        viewBox="0 0 150 150"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.5 75C12.5 40.6532 40.6532 12.5 75 12.5C109.347 12.5 137.5 40.6532 137.5 75C137.5 109.347 109.347 137.5 75 137.5C40.6532 137.5 12.5 109.347 12.5 75ZM105.992 65.8749C106.968 64.8986 106.968 63.3157 105.992 62.3394L100.688 57.0358C99.7118 56.0595 98.1289 56.0595 97.1526 57.0358L71.8894 82.299L58.9777 69.3872C58.0014 68.4109 56.4185 68.4109 55.4421 69.3872L50.131 74.6983C49.1547 75.6746 49.1547 77.2576 50.131 78.2339L63.0428 91.1456L63.0274 91.161L71.8665 100L105.992 65.8749Z"
          fill="#078880"
        />
      </svg>
    </SvgIcon>
  );
};

export default IconSuccess;
