import { SvgIcon } from "@mui/material";
import React from "react";

const IconSymptomsHistoryFilled = (props: {
  style: React.CSSProperties;
  className: string;
  color: string;
}) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={props.className}
      style={{ verticalAlign: "middle" }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9998 0.92308C5.90747 0.92308 0.922852 5.9077 0.922852 12C0.922852 18.0923 5.90747 23.0769 11.9998 23.0769C18.0921 23.0769 23.0767 18.0923 23.0767 12C23.0767 5.9077 18.0921 0.92308 11.9998 0.92308ZM13.569 12.1308C13.4305 11.9923 13.3844 11.8077 13.3844 11.6231V7.19231C13.3844 6.82308 13.0613 6.5 12.6921 6.5H11.3075C10.9382 6.5 10.6152 6.82308 10.6152 7.19231V12.7769C10.6152 12.9615 10.7075 13.1462 10.7998 13.2846L14.2152 16.7C14.4921 16.9769 14.9075 16.9769 15.1844 16.7L16.1536 15.7308C16.4305 15.4538 16.4305 15.0385 16.1536 14.7615L13.569 12.1308Z"
        fill={props.color}
      />
    </SvgIcon>
  );
};

export default IconSymptomsHistoryFilled;
