import IconHomeFilled from "./SVGs/IconHomeFilled";
import IconLearnedFilled from "./SVGs/IconLearnFilled";
import IconMyInfoFilled from "./SVGs/IconMyInfoFilled";
import IconSymptomsHistoryFilled from "./SVGs/IconSymptomHistoryFilled";
import IconHomeOutline from "./SVGs/IconHomeOutline";
import IconSymptomHistoryOutline from "./SVGs/IconSymptomHistoryOutline";
import IconMyInfoOutline from "./SVGs/IconMyInfoOutline";
import IconLearnOutline from "./SVGs/IconLearnOutline";
import IconCAM from "./SVGs/IconCAM";
import IconICM from "./SVGs/IconICM";
import IconBattery from "./SVGs/IconBattery";
import IconBatteryCAM from "./SVGs/IconBatteryCAM";
import IconCAMSuccess from "./SVGs/IconCAMSuccess";
import IconBatteryCAMSuccess from "./SVGs/IconBatteryCAMSuccess";
import IconSuccess from "./SVGs/IconSuccess";
import IconFailure from "./SVGs/IconFailure";
import IconCompanyLogo from "./SVGs/IconCompanyLogo";
import IconHomeHappy from "./SVGs/IconHomeHappy";
import IconHomeNoActiveDevice from "./SVGs/IconHomeNoActiveDevice";
import IconHomeNoTransmission from "./SVGs/IconHomeNoTransmission";
import IconHomeReturnDevice from "./SVGs/IconHomeReturnDevice";
import IconHomeSwitchPatch from "./SVGs/IconHomeSwitchPatch";
import IconCheck from "./SVGs/IconCheck";
import IconWifiOff from "./SVGs/IconWifiOff";
import IconPersonShield from "./SVGs/IconPersonShield";
import IconCheckList from "./SVGs/IconCheckList";
import Icon123Numbers from "./SVGs/Icon123Numbers";
import IconQuestionBubble from "./SVGs/IconQuestionBubble";
import IconReadingHead from "./SVGs/IconReadingHead";
import IconMovieClapperboard from "./SVGs/IconMovieClapperboard";
import IconCircledLightBulb from "./SVGs/IconCircledLightBulb";
import IconSquaredCross from "./SVGs/IconSquaredCross";
import IconPin from "./SVGs/IconPin";
import IconHandset from "./SVGs/IconHandset";

export const ICONS = {
  IconHomeFilled: IconHomeFilled,
  IconSymptomsHistoryFilled: IconSymptomsHistoryFilled,
  IconMyInfoFilled: IconMyInfoFilled,
  IconLearnFilled: IconLearnedFilled,
  IconHomeOutline: IconHomeOutline,
  IconSymptomsHistoryOutline: IconSymptomHistoryOutline,
  IconMyInfoOutline: IconMyInfoOutline,
  IconLearnOutline: IconLearnOutline,
  IconCAM: IconCAM,
  IconCamSuccess: IconCAMSuccess,
  IconICM: IconICM,
  IconBattery: IconBattery,
  IconBatteryCAM: IconBatteryCAM,
  IconBatteryCAMSuccess: IconBatteryCAMSuccess,
  IconSuccess: IconSuccess,
  IconFailure: IconFailure,
  CompanyLogo: IconCompanyLogo,
  ACTIVE_MONITORING: IconHomeHappy,
  NO_ACTIVE_DEVICE: IconHomeNoActiveDevice,
  NO_TRANSMISSION: IconHomeNoTransmission,
  RETURN_DEVICE: IconHomeReturnDevice,
  SWITCH_PATCH: IconHomeSwitchPatch,
  IconCheck: IconCheck,
  IconWifiOff: IconWifiOff,
  IconCheckList: IconCheckList,
  Icon123Numbers: Icon123Numbers,
  IconQuestionBubble: IconQuestionBubble,
  IconReadingHead: IconReadingHead,
  IconMovieClapperboard: IconMovieClapperboard,
  IconSquaredCross: IconSquaredCross,
  IconHandset: IconHandset,
  IconCircledLightBulb: IconCircledLightBulb,
  IconPin: IconPin,
  IconPersonShield: IconPersonShield
};
