import BIcon from "./Icons/IconComponent";
import { useNavigate } from "react-router";
import IconLeftArrow from "./Icons/SVGs/IconLeftArrow";
import { Box, Button, Grid } from "@mui/material";

const QuickStartComponent = () => {
  const navigate = useNavigate()

  return (
    <Grid 
      container 
      direction="column"
    >
      <Grid container item direction="column">
        <Grid item padding="24px 0 0 0" className="sticky-header">
          <Button onClick={() => navigate(-1)}>
            <BIcon
              icon={IconLeftArrow}
              style={{ height: "24px", width: "24px" }}
            />
          </Button>
        </Grid>

        <Grid item className="page-header sticky-header" sx={{ top: "68px" }}>
          User manual
        </Grid>
      </Grid>

      <Grid container  padding="0 24px" rowSpacing={{mobile: 3}} columnSpacing={{laptop: 20}}>
        <Grid item>
          <Box>Comming soon...</Box>
        </Grid>
      </Grid>

  </Grid>
  )
};

export default QuickStartComponent;
