import React from "react";

const IconCompanyLogo = props => {
  return (
    <svg
      width="192"
      height="32"
      viewBox="0 0 192 32"
      style={props.style}
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M127.819 28.0574C127.356 28.0574 125.142 28.0574 121.725 28.0574C119.923 28.0574 119.141 26.8774 119.578 25.2349C119.676 24.8832 122.933 12.5664 122.933 12.5664H132.483L133.6 8.55469H124.17L125.501 3.56219L113.867 4.28863C113.867 4.28863 110.449 16.8002 108.608 23.7775C106.764 30.7639 112.733 31.9903 114.162 31.9903C114.783 31.9903 128.777 31.9903 128.777 31.9903L130.072 27.4959L130.088 27.4373C130.088 27.4373 130.084 27.4395 130.076 27.4434C129.95 27.5077 128.845 28.0574 127.819 28.0574Z"
        fill="#154898"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M146.449 14.4735C146.764 13.4924 147.062 12.5392 148.552 12.5392H152.393C153.58 12.5392 154.433 13.5987 154.2 14.7925C153.927 16.1204 153.429 18.073 153.429 18.073H145.478C145.478 18.073 146.32 14.8757 146.449 14.4735ZM145.637 28.0903C143.352 28.0903 143.12 26.4523 143.514 25.1154C143.613 24.7775 144.384 22.1818 144.384 22.1818H163.611L165.32 15.3152C166.178 8.3378 158.715 8.58315 158.715 8.58315C158.715 8.58315 147.685 8.58315 141.55 8.58315C136.125 8.58315 135.067 13.9597 135.067 13.9597C135.067 13.9597 134.112 17.5317 132.365 24.5043C130.924 30.325 135.655 31.9909 138.609 31.9909C140.74 31.9909 160.046 31.9909 160.046 31.9909L162.132 27.5029C162.132 27.5029 160.932 28.0903 159.678 28.0903H145.637Z"
        fill="#154898"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M183.81 8.58295C180.504 8.58295 179.528 11.6738 179.528 11.6738V8.58295H168.75C168.75 8.58295 169.287 10.1425 168.947 11.4455C168.526 13.0598 165.259 25.5793 164.34 28.798C163.881 30.4008 162.722 31.8309 162.594 31.9859C162.586 31.9952 162.582 32 162.582 32H175.975C175.972 31.9936 175.969 31.9872 175.966 31.9808C175.638 31.221 175.556 30.0231 175.852 28.798C176.058 27.9191 178.665 17.6564 179.107 15.6853C179.112 15.6635 179.12 15.6452 179.125 15.6238C179.579 13.889 181.559 13.9364 181.559 13.9364C181.559 13.9364 186.334 13.9364 187.683 13.9364C189.034 13.9364 190.008 14.8339 190.008 14.8339L191.9 8.58295C191.9 8.58295 187.125 8.58295 183.81 8.58295Z"
        fill="#154898"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.592 30.4914C103.405 30.1954 96.8529 19.0074 96.8529 19.0074L111.421 8.59224H103.095C103.005 9.49452 100.943 10.8411 100.943 10.8411L94.6688 15.3521C94.6688 15.3521 91.872 10.6466 91.3515 9.69342C91.0995 9.21233 91.1087 8.58295 91.1087 8.58295H76.6125C77.2489 9.04097 77.5426 9.40195 77.9081 9.95255C78.0122 10.1053 85.1493 22.2139 85.1493 22.2139L71.5762 31.9862H78.9609L87.3655 26.0079C87.3655 26.0079 89.4012 29.4043 89.767 30.1304C90.1325 30.8847 90.3727 31.9862 90.3727 31.9862H104.869C104.869 31.9862 104.212 31.5141 103.592 30.4914Z"
        fill="#154898"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.1527 26.4982C58.0063 26.9994 57.7228 27.3188 57.425 27.5235C56.9224 27.8508 56.3123 27.8848 56.1175 27.8867C55.3953 27.8867 53.3149 27.8867 51.7237 27.8867C50.8064 27.8867 50.4022 27.5138 50.2283 27.1183C50.2142 27.0866 50.1994 27.0555 50.1885 27.0222C50.1783 26.9934 50.1709 26.9652 50.1629 26.9367C50.0908 26.6375 50.121 26.2925 50.1664 26.0366C50.3112 25.4044 50.8749 22.9691 51.2715 21.6354C51.3112 21.5009 51.3624 21.3897 51.4143 21.2802C51.8996 20.3423 52.8121 20.2898 52.9879 20.2892H59.8032C59.8032 20.2892 58.7507 24.4348 58.1527 26.4982ZM68.9527 29.0385C68.9527 29.0385 71.551 19.7062 72.6057 15.19C73.5544 11.1555 69.6862 8.62933 67.4813 8.62933C66.7955 8.62933 44.4572 8.62933 44.4572 8.62933L42.8724 12.9137C43.7964 12.4604 45.0203 12.4742 45.0203 12.4742H59.9905C60.3883 12.4742 61.1042 12.5905 61.3412 13.2324C61.3486 13.2529 61.3556 13.265 61.3624 13.2887C61.3684 13.3083 61.3694 13.3332 61.3745 13.3537C61.4104 13.5001 61.4319 13.6817 61.3838 13.8848C61.3162 14.2198 61.1715 14.7051 61.1055 14.945C60.8733 15.8008 60.4812 16.2172 59.6437 16.2172H46.663C44.6026 16.2172 41.1338 17.1426 40.1899 20.7655C39.9471 21.7142 39.5096 23.2827 39.1627 24.6337C38.1669 28.4603 41.6498 31.9721 45.3351 31.9721H54.4638C56.6348 31.9721 57.6899 29.5288 57.6899 29.5288C57.8795 30.5746 58.177 31.9721 58.177 31.9721H69.4501C68.4694 30.4913 68.9527 29.0385 68.9527 29.0385Z"
        fill="#154898"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.2974 7.35695C28.7574 9.32326 28.2574 11.2111 28.2574 11.2111C28.2574 11.2111 27.907 13.0064 26.3677 13.0064C25.0186 13.0064 18.7043 13.0064 18.7043 13.0064L20.7769 5.20998C20.7769 5.20998 26.5064 5.20998 27.7648 5.20998C27.9176 5.21191 28.2449 5.2308 28.5463 5.34547C29.0501 5.56455 29.6405 6.10618 29.2974 7.35695ZM25.7706 19.6648C25.4731 21.1035 24.6948 24.0138 24.4936 24.6294C24.296 25.2447 23.9337 26.776 21.6805 26.776C19.1079 26.776 14.945 26.776 14.945 26.776L17.2617 18.0034C17.2617 18.0034 23.3053 18.0034 24.1138 18.0034C24.1138 18.0034 24.3127 18.0056 24.5513 18.0389C25.1333 18.1398 26.0096 18.503 25.7706 19.6648ZM39.184 12.0858C39.5113 10.952 40.8646 5.91112 40.8646 5.91112C41.4119 2.79493 38.9847 0 35.3379 0C34.336 0 8.94136 0 8.94136 0C9.1486 0.721954 9.3523 1.63577 9.06532 2.71389C9.01087 2.91056 2.08224 28.3032 1.71422 29.4044C1.34748 30.4915 0 31.9722 0 31.9722C0 31.9722 27.1899 31.9722 30.4937 31.9722C33.8027 31.9722 35.3263 29.21 35.5784 28.391C35.8295 27.5444 36.7814 24.2313 37.5216 21.1314C38.5245 16.9947 34.541 15.4403 34.541 15.4403C37.8458 15.1995 38.9239 12.9555 39.184 12.0858Z"
        fill="#154898"
      />
    </svg>
  );
};

export default IconCompanyLogo;
