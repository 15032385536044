import { STORAGE_KEYS } from "../../constants/storageKeys";

export default class SessionStorageUtility {
  static storeUnmappedDeviceId(deviceId: string) {
    sessionStorage.setItem(STORAGE_KEYS.UNMAPPED_DEVICE_ID, deviceId);
  }

  static getStoredUnmappedDeviceId() {
    return sessionStorage.getItem(STORAGE_KEYS.UNMAPPED_DEVICE_ID);
  }

  static removeStoredUnmappedDeviceId() {
    return sessionStorage.removeItem(STORAGE_KEYS.UNMAPPED_DEVICE_ID);
  }

  static storeForcedAppState(state: string) {
    sessionStorage.setItem(STORAGE_KEYS.FORCED_APP_STATE, state);
  }

  static getForcedAppState() {
    return sessionStorage.getItem(STORAGE_KEYS.FORCED_APP_STATE);
  }

  static removeForcedAppState() {
    return sessionStorage.removeItem(STORAGE_KEYS.FORCED_APP_STATE);
  }
}
