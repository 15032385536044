import React from "react";

export function getBuildVersion(): IAppInfo {
     const version = (window as any).appVersion;
    return {
        buildVersion: version ? version : ""
    }
}

export interface IAppInfo {
    buildVersion: string;
}

const AppInfo = React.createContext<IAppInfo>({
    buildVersion: ""
});

export default AppInfo;