import { SvgIcon } from "@mui/material";
import React from "react";

const IconPersonShield = (props: { style: React.CSSProperties }) => {
  return (
    <SvgIcon style={props.style}>
      <svg
        viewBox="0 0 23 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.84 19.829C17.4062 19.829 17.8876 19.6258 18.284 19.2193C18.6804 18.8129 18.8787 18.3288 18.8787 17.767C18.8787 17.205 18.6797 16.725 18.2817 16.327C17.8837 15.929 17.4037 15.73 16.8417 15.73C16.2797 15.73 15.7954 15.9278 15.389 16.3233C14.9826 16.7191 14.7793 17.1997 14.7793 17.765C14.7793 18.3301 14.9824 18.8154 15.3887 19.221C15.7949 19.6263 16.2787 19.829 16.84 19.829ZM16.818 23.906C17.5411 23.906 18.1968 23.7508 18.785 23.4403C19.3734 23.1297 19.8633 22.6957 20.2547 22.1383C19.7151 21.8319 19.1622 21.6009 18.596 21.4453C18.03 21.2898 17.4413 21.212 16.83 21.212C16.2184 21.212 15.6257 21.2898 15.0517 21.4453C14.4777 21.6009 13.9282 21.8319 13.4033 22.1383C13.7949 22.6957 14.2811 23.1297 14.862 23.4403C15.4429 23.7508 16.0949 23.906 16.818 23.906ZM10 25.641C7.13111 24.8821 4.74722 23.1998 2.84833 20.594C0.949445 17.9882 0 15.0239 0 11.701V4.11467L10 0.371002L20 4.11467V12.3677C19.7402 12.2559 19.4619 12.1523 19.165 12.057C18.8681 11.9614 18.588 11.892 18.3247 11.8487V5.27267L10 2.195L1.67533 5.27267V11.701C1.67533 13.1979 1.90667 14.6009 2.36933 15.91C2.832 17.2191 3.441 18.396 4.19633 19.4407C4.95145 20.4853 5.80967 21.3759 6.771 22.1123C7.73233 22.8488 8.713 23.3926 9.713 23.7437L9.75133 23.7307C9.87622 24.0031 10.0458 24.288 10.26 24.5853C10.4742 24.8829 10.6801 25.1319 10.8777 25.3323C10.7312 25.4066 10.5836 25.4654 10.4347 25.509C10.2856 25.5526 10.1407 25.5966 10 25.641ZM16.8717 25.6667C15.2681 25.6667 13.9014 25.0994 12.7717 23.965C11.6419 22.8306 11.077 21.4684 11.077 19.8787C11.077 18.2624 11.6418 16.8878 12.7713 15.7547C13.9011 14.6213 15.2716 14.0547 16.8827 14.0547C18.4789 14.0547 19.8442 14.6213 20.9787 15.7547C22.1131 16.8878 22.6803 18.2624 22.6803 19.8787C22.6803 21.4684 22.1131 22.8306 20.9787 23.965C19.8442 25.0994 18.4752 25.6667 16.8717 25.6667Z"
          fill="#154898"
        />
      </svg>
    </SvgIcon>
  );
};

export default IconPersonShield;
