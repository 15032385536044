import { Button, Grid } from "@mui/material";
import React from "react";
import BIcon from "./Icons/IconComponent";
import { ICONS } from "./Icons/IconComponentMapping";
import { useLocation, useNavigate } from "react-router";
import { APP_ROUTES } from "../constants/appRoutes";

type MessageStateContent = {
  type: string;
  messageText: string;
  nextRoute: string;
};
const MessageComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { messageText, type, nextRoute } =
    location.state as MessageStateContent;

  if (!type) return <>Something is not right!</>;
  return (
    <Grid
      container
      direction="column"
      justifyContent="space-around"
      height="100%"
    >
      <Grid
        container
        item
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <h1
          className={`message-header ${
            type === "success" ? "success" : "failure"
          }`}
        >
          {type === "success" ? "Success!" : "Failure!"}
        </h1>
        <div className={`${type === "success" ? "text-info" : "text-error"}`}>
          {messageText}
        </div>
      </Grid>
      <Grid container item direction="row" justifyContent="center">
        <BIcon
          icon={
            type === "success" ? ICONS["IconSuccess"] : ICONS["IconFailure"]
          }
          style={{
            height: "200px",
            minWidth: "100%",
            width: "100%",
            fill: "none",
          }}
        />
      </Grid>
      <Grid container item direction="row" justifyContent="center">
        <Button
          variant="outlined"
          sx={{ width: "80%" }}
          className="baxi-button baxi-primary-button"
          onClick={() => navigate(nextRoute)}
        >
          Done
        </Button>
      </Grid>
    </Grid>
  );
};

export default MessageComponent;
