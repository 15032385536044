import { SvgIcon } from "@mui/material";
import React from "react";

const IconShieldWithPerson = (props: { style: React.CSSProperties }) => {
  return (
    <SvgIcon style={props.style}>
      <svg
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.84 22.829C23.4062 22.829 23.8876 22.6258 24.284 22.2193C24.6804 21.8129 24.8787 21.3288 24.8787 20.767C24.8787 20.205 24.6797 19.725 24.2817 19.327C23.8837 18.929 23.4037 18.73 22.8417 18.73C22.2797 18.73 21.7954 18.9278 21.389 19.3233C20.9826 19.7191 20.7793 20.1997 20.7793 20.765C20.7793 21.3301 20.9824 21.8154 21.3887 22.221C21.7949 22.6263 22.2787 22.829 22.84 22.829ZM22.818 26.906C23.5411 26.906 24.1968 26.7508 24.785 26.4403C25.3734 26.1297 25.8633 25.6957 26.2547 25.1383C25.7151 24.8319 25.1622 24.6009 24.596 24.4453C24.03 24.2898 23.4413 24.212 22.83 24.212C22.2184 24.212 21.6257 24.2898 21.0517 24.4453C20.4777 24.6009 19.9282 24.8319 19.4033 25.1383C19.7949 25.6957 20.2811 26.1297 20.862 26.4403C21.4429 26.7508 22.0949 26.906 22.818 26.906ZM16 28.641C13.1311 27.8821 10.7472 26.1998 8.84833 23.594C6.94944 20.9882 6 18.0239 6 14.701V7.11467L16 3.371L26 7.11467V15.3677C25.7402 15.2559 25.4619 15.1523 25.165 15.057C24.8681 14.9614 24.588 14.892 24.3247 14.8487V8.27267L16 5.195L7.67533 8.27267V14.701C7.67533 16.1979 7.90667 17.6009 8.36933 18.91C8.832 20.2191 9.441 21.396 10.1963 22.4407C10.9514 23.4853 11.8097 24.3759 12.771 25.1123C13.7323 25.8488 14.713 26.3926 15.713 26.7437L15.7513 26.7307C15.8762 27.0031 16.0458 27.288 16.26 27.5853C16.4742 27.8829 16.6801 28.1319 16.8777 28.3323C16.7312 28.4066 16.5836 28.4654 16.4347 28.509C16.2856 28.5526 16.1407 28.5966 16 28.641ZM22.8717 28.6667C21.2681 28.6667 19.9014 28.0994 18.7717 26.965C17.6419 25.8306 17.077 24.4684 17.077 22.8787C17.077 21.2624 17.6418 19.8878 18.7713 18.7547C19.9011 17.6213 21.2716 17.0547 22.8827 17.0547C24.4789 17.0547 25.8442 17.6213 26.9787 18.7547C28.1131 19.8878 28.6803 21.2624 28.6803 22.8787C28.6803 24.4684 28.1131 25.8306 26.9787 26.965C25.8442 28.0994 24.4752 28.6667 22.8717 28.6667Z"
          fill="#154898"
        />
      </svg>
    </SvgIcon>
  );
};

export default IconShieldWithPerson;
