import gql from "graphql-tag";

export const SYMPTOMS = gql`
  query symptoms {
    symptoms(limit: 10) {
      id
      name
    }
  }
`;

// Mutation to get symptoms from database
export const M_GET_SYMPTOMS = gql`
  mutation GetSymptoms {
    getSymptoms {
      name
      displayName
      displayOrder
    }
  }
`;

// Mutation to send selected symptoms
export const M_RECORD_SYMPTOMS = gql`
  mutation RecordSymptoms($input: SymptomsInputType!) {
    recordSymptoms(input: $input)
  }
`;

// Mutation to get history of the recorded symptoms
export const M_GET_SYMPTOMS_HISTORY = gql`
  mutation ReadSymptomsHistory($input: SymptomsHistoryInputType!) {
    readSymptomsHistory(input: $input) {
      date
      symptomsHistory {
        timestamp
        symptomDisplayNames
      }
    }
  }
`;
