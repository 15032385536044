import { SvgIcon } from "@mui/material";
import React from "react";

const IconLeftArrow = (props: { style: React.CSSProperties }) => {
  return (
    <SvgIcon style={props.style}>
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22.4075 10.1769H7.08439C6.66901 10.1769 6.48439 9.66923 6.76131 9.3923L11.1921 4.96153C11.469 4.68461 11.469 4.26923 11.1921 3.9923L10.1767 2.97692C9.89977 2.7 9.48439 2.7 9.20747 2.97692L1.13054 11.1C0.853621 11.3769 0.853621 11.7923 1.13054 12.0692L9.20747 20.1461C9.48439 20.4231 9.89977 20.4231 10.1767 20.1461L11.1459 19.1769C11.4229 18.9 11.4229 18.4846 11.1459 18.2077L6.71516 13.7769C6.43824 13.4538 6.62285 12.9461 7.03824 12.9461H22.3613C22.7305 12.9461 23.0536 12.6692 23.0536 12.3V10.9154C23.0536 10.5461 22.7767 10.1769 22.4075 10.1769Z"
          fill="#1A1C1C"
          fill-opacity="0.79"
        />
      </svg>
    </SvgIcon>
  );
};

export default IconLeftArrow;
