import { useNavigate } from "react-router";
import { useEffect } from "react";
import { APP_ROUTES } from "../constants/appRoutes";
import { useSearchParams } from "react-router-dom";
import SessionStorageUtility from "./utilities/SessionStorageUtility";
import { FORCED_APP_STATE } from "../constants/forcedAppStates";

const ForceAppStateComponent = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get("clear") !== null) {
      SessionStorageUtility.removeForcedAppState();
    } else if (searchParams.get("state")) {
      const forcedState = searchParams.get("state");
      switch (forcedState) {
        case FORCED_APP_STATE.NO_ACTIVE_DEVICE:
            SessionStorageUtility.removeStoredUnmappedDeviceId()
            SessionStorageUtility.storeForcedAppState(forcedState);
            break;
        case FORCED_APP_STATE.NO_TRANSMISSION:
            SessionStorageUtility.storeForcedAppState(forcedState);
            break;
        default:
            console.error(`Forced app state is invalid: ${forcedState}`)
            break;
      }
    }

    navigate(APP_ROUTES.ROOT);
  });
  return <></>;
};

export default ForceAppStateComponent;
