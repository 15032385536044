import { SvgIcon } from "@mui/material";
import React from "react";

const IconLocationRectangled = (props: { style: React.CSSProperties }) => {
  return (
    <SvgIcon style={props.style}>
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.0019 11.8652C12.4994 11.8652 12.9247 11.6881 13.2779 11.3338C13.6312 10.9794 13.8079 10.5535 13.8079 10.056C13.8079 9.5585 13.6307 9.13308 13.2764 8.77975C12.9221 8.42658 12.4961 8.25 11.9984 8.25C11.5009 8.25 11.0756 8.42717 10.7224 8.7815C10.3691 9.13583 10.1924 9.56183 10.1924 10.0595C10.1924 10.557 10.3696 10.9823 10.7239 11.3355C11.0782 11.6887 11.5042 11.8652 12.0019 11.8652ZM12.0001 19.5135C13.9565 17.7622 15.4536 16.0823 16.4914 14.474C17.5292 12.8657 18.0481 11.457 18.0481 10.248C18.0481 8.425 17.469 6.92633 16.3106 5.752C15.1523 4.57767 13.7155 3.9905 12.0001 3.9905C10.2848 3.9905 8.84798 4.57767 7.68965 5.752C6.53131 6.92633 5.95215 8.425 5.95215 10.248C5.95215 11.457 6.47107 12.8657 7.5089 14.474C8.54673 16.0823 10.0438 17.7622 12.0001 19.5135ZM12.0001 21.5095C9.48348 19.3288 7.59631 17.2994 6.33865 15.4212C5.08098 13.5429 4.45215 11.8185 4.45215 10.248C4.45215 7.94033 5.19856 6.07208 6.6914 4.64325C8.1844 3.21442 9.95398 2.5 12.0001 2.5C14.0463 2.5 15.8159 3.21442 17.3089 4.64325C18.8017 6.07208 19.5481 7.94033 19.5481 10.248C19.5481 11.8185 18.9193 13.5429 17.6616 15.4212C16.404 17.2994 14.5168 19.3288 12.0001 21.5095Z"
          fill="#484C4E"
        />
      </svg>
    </SvgIcon>
  );
};

export default IconLocationRectangled;
