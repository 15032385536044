import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { Box, Button, Grid } from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React, { useContext } from "react";
import Config, { IConfig } from "../types/Config";
import GeneralLoginComponent from "./login/GeneralLoginComponent";
import AppInfo, { IAppInfo } from "../types/AppInfo";
import DeviceWearTime from "./shared/DeviceWearTimeComponent";
import { DeviceStatus, IDeviceDetails } from "./shared/DeviceDetailsContext";
import MyInfoLoginComponent from "./login/MyInfoLoginComponent";
import { BaxiProgressScale } from "./shared/BaxiProgressComponent/BaxiProgressComponent";
import BIcon from "./Icons/IconComponent";
import IconMCTPatch from "./Icons/SVGs/IconMCTPatch";
import IconShieldWithPerson from "./Icons/SVGs/IconShieldWithPerson";
import IconCheckedList from "./Icons/SVGs/IconCheckedList";
import Icon123Numbers from "./Icons/SVGs/Icon123Numbers";
import IconRedCrossRectangled from "./Icons/SVGs/IconRedCrossRectangled";
import IconLocationRectangled from "./Icons/SVGs/IconLocationRectangled";
import IconHeadsetRectangled from "./Icons/SVGs/IconHeadsetRectangled";
import IconQuestionBubble from "./Icons/SVGs/IconQuestionBubble";
import { useNavigate } from "react-router";
import { APP_ROUTES } from "../constants/appRoutes";

const MyInfoComponent = () => {
  const { instance } = useMsal();
  const navigate = useNavigate()
  const config = React.useContext<IConfig>(Config);
  const appInfo = React.useContext<IAppInfo>(AppInfo)
  const deviceDetails = useContext<IDeviceDetails>(DeviceStatus)

  const handleLogoutRedirect = () => {
    instance.logoutRedirect({postLogoutRedirectUri: location.href});
  };
  const handleChangePasswordRedirect = () => {
    instance.loginRedirect({
      scopes: [],
      authority: config.b2cPolicies.authorities.passwordChange.authority,
    });
  };
  return (
    <>
      <UnauthenticatedTemplate>
        { 
          deviceDetails.deviceType ? 
            <MyInfoLoginComponent /> : 
            <GeneralLoginComponent /> 
        }
      </UnauthenticatedTemplate>

      <AuthenticatedTemplate>
        <Grid 
        container 
        direction="column"
      >
        <Grid item className="page-header sticky-header">
            My Info
        </Grid>

        <Grid item direction="row" justifyContent="center">
          <DeviceWearTime
            size={BaxiProgressScale.MEDIUM}
            prescribedWearTimeDays={deviceDetails.prescribedWearTimeDays}
            recordingStartedAt={+deviceDetails.recordingStartedAt} />
        </Grid>
          
        <Grid item className="page-secondary-header sticky-header">
            Clinic information
        </Grid>

        <Grid container  padding="0 24px" rowSpacing={{mobile: 1}} columnSpacing={{laptop: 20}}>
          <Grid item mobile={12} laptop={6} display="flex" justifyContent="space-between" >
            <Box display="flex" alignItems="center">
              <BIcon icon={IconRedCrossRectangled} style={{height: "24px", width: "24px"}} />
              <Box paddingLeft="16px"><i>[Name placeholder]</i></Box>
            </Box>
          </Grid>
          <Grid item mobile={12} laptop={6} display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <BIcon icon={IconLocationRectangled} style={{height: "24px", width: "24px"}} />
              <Box paddingLeft="16px"><i>[Address placeholder]</i></Box>
            </Box>
          </Grid>
          <Grid item mobile={12} laptop={6} display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <BIcon icon={IconHeadsetRectangled} style={{height: "24px", width: "24px"}} />
              <Box paddingLeft="16px"><i>[Phone placeholder]</i></Box>
            </Box>
          </Grid>
        </Grid>

        <Grid item className="page-secondary-header sticky-header">
            About
        </Grid>

        <Grid container  padding="0 24px" rowSpacing={{mobile: 2}} columnSpacing={{laptop: 20}}>
          <Grid item mobile={12} laptop={6} display="flex" justifyContent="space-between"  
                onClick={() => navigate(APP_ROUTES.TECH_SUPPORT)}
          >
            <Box display="flex" alignItems="center">
              <BIcon icon={IconQuestionBubble} style={{height: "32px", width: "32px"}} />
              <Box paddingLeft="16px">Support</Box>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center">
              <ChevronRightIcon />
            </Box>
          </Grid>
          <Grid item mobile={12} laptop={6} display="flex" justifyContent="space-between"  
                onClick={() => navigate(APP_ROUTES.DEVICE_INFO)}
          >
            <Box display="flex" alignItems="center">
              <BIcon icon={IconMCTPatch} style={{height: "32px", width: "32px"}} />
              <Box paddingLeft="16px">Device information</Box>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center">
              <ChevronRightIcon />
            </Box>
          </Grid>
          <Grid item mobile={12} laptop={6} display="flex" justifyContent="space-between"  
                onClick={() => navigate(APP_ROUTES.DATA_POLICY)}
          >
            <Box display="flex" alignItems="center">
              <BIcon icon={IconShieldWithPerson} style={{height: "32px", width: "32px"}} />
              <Box paddingLeft="16px">Privacy policies</Box>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center">
              <ChevronRightIcon />
            </Box>
          </Grid>
          <Grid item mobile={12} laptop={6} display="flex" justifyContent="space-between"  
                onClick={() => navigate(APP_ROUTES.TERMS_OF_USE)}
          >
            <Box display="flex" alignItems="center">
              <BIcon icon={IconCheckedList} style={{height: "32px", width: "32px"}} />
              <Box paddingLeft="16px">End user licence agreement</Box>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center">
              <ChevronRightIcon />
            </Box>
          </Grid>
          <Grid item mobile={12} laptop={6} display="flex" justifyContent="space-between"  
                onClick={() => navigate(APP_ROUTES.BUILD_INFO)}
          >
            <Box display="flex" alignItems="center">
              <BIcon icon={Icon123Numbers} style={{height: "32px", width: "32px"}} />
              <Box paddingLeft="16px">Build number</Box>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center">
              <ChevronRightIcon />
            </Box>
          </Grid>
        </Grid>

        <Grid container justifyContent="center" marginTop="8px" padding="24px" >
          <Button
            variant="outlined"
            sx={{width: "80%"}}
            className="baxi-button baxi-primary-button"
            onClick={handleLogoutRedirect}
          >
            Log Out
          </Button>
        </Grid>

          {/* <Grid 
            container justifyContent="center" marginTop="8px" padding="24px"
          >
            <Button
              variant="outlined"
              sx={{width: "80%"}}
              className="baxi-button baxi-primary-button"
              onClick={handleChangePasswordRedirect}
              >
                Change Password
            </Button>
          </Grid> */}
        </Grid>
      </AuthenticatedTemplate>
    </>
  );
};

export default MyInfoComponent;
