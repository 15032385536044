import { SvgIcon } from "@mui/material";
import React from "react";

const IconCheckList = (props: { style: React.CSSProperties }) => {
  return (
    <SvgIcon style={props.style}>
      <svg
        viewBox="0 0 26 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.74367 23.3333C2.07011 23.3333 1.5 23.1 1.03333 22.6333C0.566666 22.1667 0.333333 21.5966 0.333333 20.923V3.077C0.333333 2.40345 0.566666 1.83334 1.03333 1.36667C1.5 0.900005 2.07011 0.666672 2.74367 0.666672H23.2563C23.9299 0.666672 24.5 0.900005 24.9667 1.36667C25.4333 1.83334 25.6667 2.40345 25.6667 3.077V20.923C25.6667 21.5966 25.4333 22.1667 24.9667 22.6333C24.5 23.1 23.9299 23.3333 23.2563 23.3333H2.74367ZM2.74367 21.3333H23.2563C23.359 21.3333 23.453 21.2906 23.5383 21.205C23.6239 21.1197 23.6667 21.0257 23.6667 20.923V3.077C23.6667 2.97434 23.6239 2.88034 23.5383 2.795C23.453 2.70945 23.359 2.66667 23.2563 2.66667H2.74367C2.641 2.66667 2.547 2.70945 2.46167 2.795C2.37611 2.88034 2.33333 2.97434 2.33333 3.077V20.923C2.33333 21.0257 2.37611 21.1197 2.46167 21.205C2.547 21.2906 2.641 21.3333 2.74367 21.3333ZM4 18.3333H10V16.3333H4V18.3333ZM16.4 15.5257L22.5257 9.4L21.1 7.97434L16.4 12.7077L14.5 10.8077L13.1077 12.2333L16.4 15.5257ZM4 13H10V11H4V13ZM4 7.66667H10V5.66667H4V7.66667Z"
          fill="#154898"
        />
      </svg>
    </SvgIcon>
  );
};

export default IconCheckList;
