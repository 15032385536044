import { Box, Button, Grid } from "@mui/material";
import BIcon from "./Icons/IconComponent";
import { useNavigate } from "react-router";
import IconLeftArrow from "./Icons/SVGs/IconLeftArrow";

const VideoInstructions = () => {
  const navigate = useNavigate();

  return (
    <>
      <Grid container direction="column">
        <Grid item padding="24px 0 0 0" className="sticky-header">
          <Button onClick={() => navigate(-1)}>
            <BIcon
              icon={IconLeftArrow}
              style={{ height: "24px", width: "24px" }}
            />
          </Button>
        </Grid>

        <Grid item className="page-header sticky-header" sx={{ top: "67px" }}>
          Video instructions
        </Grid>

        <Grid container padding="0 24px 0 24px" columnSpacing={{ laptop: 20 }}>
          <Grid
            item
            container
            padding="0 0 16px 0"
            className="sticky-header"
            sx={{ top: "146px" }}
          >
            <Box>New CAM Application video</Box>
          </Grid>
          <Grid item container padding="0 0 24px 0">
            <Grid
              item
              container
              sx={{ borderRadius: "8px" }}
              className="video-instruction-container"
            >
              <iframe
                style={{ border: "none", borderRadius: "8px"}}
                width="100%"
                height="215px"
                src="https://www.youtube.com/embed/RPcdb-volpc?si=aHyh2M_qLrQyzwjP"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </Grid>
          </Grid>
          <Grid
            item
            container
            padding="0 0 16px 0"
            className="sticky-header"
            sx={{ top: "146px" }}
          >
            <Box>Sustaining and Saving Lives</Box>
          </Grid>
          <Grid item container padding="0 0 24px 0">
            <Grid
              item
              container
              sx={{ borderRadius: "8px" }}
              className="video-instruction-container"
            >
              <iframe
                style={{ border: "none", borderRadius: "8px" }}
                width="100%"
                height="215px"
                src="https://www.youtube.com/embed/Xpb4ogirD3Q?si=ProM86imKa-Qtt0S"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </Grid>
          </Grid>
          <Grid
            item
            container
            padding="0 0 16px 0"
            className="sticky-header"
            sx={{ top: "146px" }}
          >
            <Box>Women of Baxter IWD 2022</Box>
          </Grid>
          <Grid item container padding="0 0 24px 0">
            <Grid
              item
              container
              sx={{ borderRadius: "8px" }}
              className="video-instruction-container"
            >
              <iframe
                style={{ border: "none", borderRadius: "8px" }}
                width="100%"
                height="215px"
                src="https://www.youtube.com/embed/4Dn1jtcaDSA?si=OAXgyJhaAAPxtc5L"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </Grid>
          </Grid>
          <Grid
            item
            container
            padding="0 0 16px 0"
            className="sticky-header"
            sx={{ top: "146px" }}
          >
            <Box>Corporate Responsibility Highlights</Box>
          </Grid>
          <Grid item container padding="0 0 24px 0">
            <Grid
              item
              container
              sx={{ borderRadius: "8px" }}
              className="video-instruction-container"
            >
              <iframe
                style={{ border: "none", borderRadius: "8px" }}
                width="100%"
                height="215px"
                src="https://www.youtube.com/embed/p6c2fhWrc2A?si=iL3Fv9u4VlGnkqlP"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default VideoInstructions;
